import { stringify } from 'query-string';

import { api } from 'shared/api/api-methods';
import {
  ActivityIconListResponseSchema,
  ActivityListResponseSchema,
  ActivityResponseSchema,
  AnnouncementResponceSchema,
  ApiActivitiesSchemasIssueTypeListResponseSchema,
  ApiChatsSchemaResponsesIssueTypeListResponseSchema,
  ChangeEmailResponseSchema,
  ChatListResponseSchema,
  ChatNotificationSettingsRequestSchema,
  CreateChatMessageRequestSchema,
  CreateGroupChatRequestSchema,
  CreatePrivateChatRequestSchema,
  GetAvailableChatParticipantsChatsChatIdAvailableParticipantsGetParams,
  GetAvailableIssueTypesForNewChatChatsAvailableIssueTypesGetParams,
  GetAvailableParticipantsForNewChatChatsAvailableParticipantsGetParams,
  GetChatMessagesChatsChatIdMessagesGetParams,
  GetDeletedChatMessageIdsChatsChatIdDeletedMessageIdsGetParams,
  GetIssueListIssuesIssueListTypeGetParams,
  GetManagedObjectsHierarchyManagedObjectHierarchyMoInternalNameGetParams,
  GetUserNotificationsNotificationsGetParams,
  HealthcheckSchema,
  IssueListResponseSchema,
  ManagedObjectListResponseSchema,
  MessageIdListResponseSchema,
  MessageListResponseSchema,
  MessageResponseSchema,
  NotificationListResponseSchema,
  NotificationSchemaOut,
  NotificatonsUnseenCountResponceSchema,
  ParticipantsListResponseSchema,
  RegisterMCByLeadRequestSchema,
  RoleRelationsSchema,
  SingleChatResponseSchema,
  SingleMessageResponseSchema,
  SMSSettingsSchema,
  UnreadIssuesCountersResponseSchema,
  UpdateActivityDataSchema,
  UpdateGroupChatRequestSchema,
  UpdateLastReadMessageRequestSchema,
  UpdateSMSSettingsSchema,
} from 'shared/api/v4/swagger/data-contracts';

import {
  getUrlActivities,
  getUrlActivitiesByActivityId,
  getUrlActivitiesByActivityIdIssueTypes,
  getUrlActivitiesIcons,
  getUrlChats,
  getUrlChatsAvailableIssueTypes,
  getUrlChatsAvailableParticipants,
  getUrlChatsByChatId,
  getUrlChatsByChatIdAvailableParticipants,
  getUrlChatsByChatIdDeletedMessageIds,
  getUrlChatsByChatIdLeave,
  getUrlChatsByChatIdMessages,
  getUrlChatsByChatIdMessagesByMessageId,
  getUrlChatsByChatIdNotifications,
  getUrlChatsByChatIdUpdateLastReadMessage,
  getUrlChatsGroup,
  getUrlChatsIssuesByIssueId,
  getUrlChatsPrivate,
  getUrlHealthcheck,
  getUrlIssuesByIssueListType,
  getUrlIssuesUnseen,
  getUrlManagedObjectHierarchyByMoInternalName,
  getUrlManagedObjectHierarchyByMoInternalNameByParentIdDirectChildren,
  getUrlManagementCompaniesRegister,
  getUrlNotifications,
  getUrlNotificationsByNotificationId,
  getUrlNotificationsByNotificationIdAnnouncement,
  getUrlNotificationsByNotificationIdSeen,
  getUrlNotificationsSeenByLastNotificationId,
  getUrlNotificationsUnseenCount,
  getUrlRolesByRoleId,
  getUrlRolesByRoleIdRoleRelations,
  getUrlSmsSettings,
  getUrlSmsSettingsBySmsSettingsId,
  getUrlSmsSettingsProviderNames,
  getUrlUsersConfirmEmailByToken,
  getUrlUsersSetEmail,
} from './swagger/api-routes';

class SmsSettingApi {
  // Get SMS settings
  getSmsSettings() {
    return api.get<SMSSettingsSchema>(getUrlSmsSettings());
  }

  // Change SMS settings
  updateSmsSettings({
    smsSettingsId,
    item,
  }: {
    smsSettingsId: number;
    item: UpdateSMSSettingsSchema;
  }) {
    return api.put<SMSSettingsSchema>(getUrlSmsSettingsBySmsSettingsId({ smsSettingsId }), {
      body: item,
    });
  }

  // Get SMS provider names
  getSmsProviderNames() {
    return api.get<string[]>(getUrlSmsSettingsProviderNames());
  }
}

class ActivitiesSettingsApi {
  // Get activities
  getActivities() {
    return api.get<ActivityListResponseSchema>(getUrlActivities());
  }

  // Get activity issue type
  getActivityIssueType({ activityId }: { activityId: number }) {
    return api.get<ApiActivitiesSchemasIssueTypeListResponseSchema>(
      getUrlActivitiesByActivityIdIssueTypes({ activityId }),
    );
  }

  // Update activity
  updateActivity({ activityId, item }: { activityId: number; item: UpdateActivityDataSchema }) {
    return api.put<ActivityResponseSchema>(getUrlActivitiesByActivityId({ activityId }), {
      body: item,
    });
  }

  // Create activity
  createActivity({ item }: { item: UpdateActivityDataSchema }) {
    return api.post<ActivityResponseSchema>(getUrlActivities(), {
      body: item,
    });
  }

  // Delete activity
  deleteActivity({ activityId }: { activityId: number }) {
    return api.delete<void>(getUrlActivitiesByActivityId({ activityId }));
  }

  // Get activity icons
  getActivitiesIcons() {
    return api.get<ActivityIconListResponseSchema>(getUrlActivitiesIcons());
  }
}

class ManagementCompaniesApi {
  // Register management company
  registerManagementCompany({ item }: { item: RegisterMCByLeadRequestSchema }) {
    return api.post<string>(getUrlManagementCompaniesRegister(), { body: item });
  }
}

class HealthCheckApi {
  // HealthCheck
  healthCheck() {
    return api.get<HealthcheckSchema>(getUrlHealthcheck());
  }
}

class ChatsApi {
  // Create group chat
  createGroupChat({ item }: { item: CreateGroupChatRequestSchema }) {
    return api.post<SingleChatResponseSchema>(getUrlChatsGroup(), { body: item });
  }

  // Create a private chat
  createPrivateChat({ item }: { item: CreatePrivateChatRequestSchema }) {
    return api.post<SingleChatResponseSchema>(getUrlChatsPrivate(), { body: item });
  }

  // Get available participants for a new chat
  getAvailableParticipantsForNewChat(
    params: GetAvailableParticipantsForNewChatChatsAvailableParticipantsGetParams,
  ) {
    return api.get<ParticipantsListResponseSchema>(
      `${getUrlChatsAvailableParticipants()}?${stringify(params)}`,
    );
  }

  // Get available issue types for a new chat
  getAvailableIssueTypes(
    params: GetAvailableIssueTypesForNewChatChatsAvailableIssueTypesGetParams,
  ) {
    return api.get<ApiChatsSchemaResponsesIssueTypeListResponseSchema>(
      `${getUrlChatsAvailableIssueTypes()}?${stringify(params)}`,
    );
  }

  // Get chat by id
  getChatById({ chatId }: { chatId: number }) {
    return api.get<SingleChatResponseSchema>(getUrlChatsByChatId({ chatId }));
  }

  // Delete chat
  deleteChat({ chatId }: { chatId: number }) {
    return api.delete<void>(getUrlChatsByChatId({ chatId }));
  }

  // Update chat
  updateChat({ chatId, item }: { chatId: number; item: UpdateGroupChatRequestSchema }) {
    return api.patch<SingleChatResponseSchema>(getUrlChatsByChatId({ chatId }), { body: item });
  }

  // Get chats list
  getChatsList() {
    return api.get<ChatListResponseSchema>(getUrlChats());
  }

  // Get chat messages
  getChatMessages({
    chatId,
    params,
  }: {
    chatId: number;
    params: GetChatMessagesChatsChatIdMessagesGetParams;
  }) {
    return api.get<MessageListResponseSchema>(
      `${getUrlChatsByChatIdMessages({ chatId })}?${stringify(params)}`,
    );
  }

  // Create chat message
  createChatMessage({ chatId, item }: { chatId: number; item: CreateChatMessageRequestSchema }) {
    return api.post<SingleMessageResponseSchema>(getUrlChatsByChatIdMessages({ chatId }), {
      body: item,
    });
  }

  // Get deleted chat message Ids
  getDeletedChatMessageIds({
    chatId,
    params,
  }: {
    chatId: number;
    params: GetDeletedChatMessageIdsChatsChatIdDeletedMessageIdsGetParams;
  }) {
    return api.get<MessageIdListResponseSchema>(
      `${getUrlChatsByChatIdDeletedMessageIds({ chatId })}?${stringify(params)}`,
    );
  }

  // Get chat message
  getChatMessage({ chatId, messageId }: { chatId: number; messageId: number }) {
    return api.get<MessageResponseSchema>(
      getUrlChatsByChatIdMessagesByMessageId({ chatId, messageId }),
    );
  }

  // Delete chat message
  deleteChatMessage({ chatId, messageId }: { chatId: number; messageId: number }) {
    return api.delete<void>(getUrlChatsByChatIdMessagesByMessageId({ chatId, messageId }));
  }

  // Get available chat participants
  getAvailableParticipants({
    chatId,
    params,
  }: {
    chatId: number;
    params: GetAvailableChatParticipantsChatsChatIdAvailableParticipantsGetParams;
  }) {
    return api.get<ParticipantsListResponseSchema>(
      `${getUrlChatsByChatIdAvailableParticipants({ chatId })}?${stringify(params)}`,
    );
  }

  // Leave chat
  leaveChat({ chatId }: { chatId: number }) {
    return api.get<void>(getUrlChatsByChatIdLeave({ chatId }));
  }

  // Update last read message
  updateLastReadMessage({
    chatId,
    item,
  }: {
    chatId: number;
    item: UpdateLastReadMessageRequestSchema;
  }) {
    return api.put<void>(getUrlChatsByChatIdUpdateLastReadMessage({ chatId }), { body: item });
  }

  // Get issue
  getIssue({ issueId }: { issueId: number }) {
    return api.get<string>(getUrlChatsIssuesByIssueId({ issueId }));
  }

  // Update chat notification settings
  updateNotificationSettings({
    chatId,
    item,
  }: {
    chatId: number;
    item: ChatNotificationSettingsRequestSchema;
  }) {
    return api.post<SingleChatResponseSchema>(getUrlChatsByChatIdNotifications({ chatId }), {
      body: item,
    });
  }
}

class NotificationsApi {
  // Get user notification
  getNotifications({ params }: { params: GetUserNotificationsNotificationsGetParams }) {
    return api.get<NotificationListResponseSchema>(`${getUrlNotifications()}/`, { search: params });
  }

  // Get user notification
  getNotificationById({ notificationId }: { notificationId: number }) {
    return api.get<NotificationSchemaOut>(getUrlNotificationsByNotificationId({ notificationId }));
  }

  // Get announcement
  getAnnouncementById({ notificationId }: { notificationId: number }) {
    return api.get<AnnouncementResponceSchema>(
      getUrlNotificationsByNotificationIdAnnouncement({ notificationId }),
    );
  }

  // Mark as seen
  markSeen({ notificationId }: { notificationId: number }) {
    return api.put<void>(getUrlNotificationsByNotificationIdSeen({ notificationId }));
  }

  // Mark all as seen
  markAllSeen({ lastNotificationId }: { lastNotificationId: number }) {
    return api.put<void>(getUrlNotificationsSeenByLastNotificationId({ lastNotificationId }));
  }

  // Get count of unseen
  getUnseenCount() {
    return api.get<NotificatonsUnseenCountResponceSchema>(getUrlNotificationsUnseenCount());
  }
}

class UsersApi {
  // Confirm user email
  confirmEmail({ token }: { token: string }) {
    return api.get<string>(getUrlUsersConfirmEmailByToken({ token }));
  }

  // Set user email
  setEmail({ email }: { email: string }) {
    return api.post<ChangeEmailResponseSchema>(getUrlUsersSetEmail(), {
      body: { new_email: email },
    });
  }
}

class LocationsApi {
  getLocations(
    params: Omit<
      GetManagedObjectsHierarchyManagedObjectHierarchyMoInternalNameGetParams,
      'moInternalName'
    >,
  ) {
    return api.get<ManagedObjectListResponseSchema>(
      `${getUrlManagedObjectHierarchyByMoInternalName({ moInternalName: 'location' })}`,
      { params },
    );
  }

  getLocationChildrenByParentId({ parentId }: { parentId: number }) {
    return api.get<ManagedObjectListResponseSchema>(
      getUrlManagedObjectHierarchyByMoInternalNameByParentIdDirectChildren({
        moInternalName: 'location',
        parentId,
      }),
    );
  }
}

class IssuesApi {
  // get issues list
  getIssuesList({ issueListType, ...payload }: GetIssueListIssuesIssueListTypeGetParams) {
    return api.get<IssueListResponseSchema>(getUrlIssuesByIssueListType({ issueListType }), {
      search: { ...payload },
    });
  }

  // get unseen counters
  getUnseenCounters() {
    return api.get<UnreadIssuesCountersResponseSchema>(getUrlIssuesUnseen());
  }
}

class RolesApi {
  // Get role relations
  getRoleRelations({ roleId }: { roleId: number }) {
    return api.get<RoleRelationsSchema>(getUrlRolesByRoleIdRoleRelations({ roleId }));
  }

  // Delete user role
  deleteRole({ roleId }: { roleId: number }) {
    return api.delete<void>(getUrlRolesByRoleId({ roleId }));
  }
}

export const apiClient_v4 = {
  smsSettings: new SmsSettingApi(),
  activities: new ActivitiesSettingsApi(),
  managementCompanies: new ManagementCompaniesApi(),
  healthCheck: new HealthCheckApi(),
  chats: new ChatsApi(),
  notifications: new NotificationsApi(),
  users: new UsersApi(),
  locations: new LocationsApi(),
  issues: new IssuesApi(),
  roles: new RolesApi(),
};
