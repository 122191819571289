import { useState } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { bem } from 'lib/bem';
import { t, t_prefixed } from 'lib/i18n';
import { Button, FormBuilder, Logo } from 'lib/ui';
import users from 'app/users/users.resource';
import ConfirmCodePage from 'app/users/ConfirmCodePage';
import { CaptchaModel } from 'app/captcha/types';
import { useCaptchaModal } from 'app/captcha/lib/useCaptchaModal';
import { closeModal } from 'lib/ui/Modal';
import { PrivacyFooter } from 'shared/ui/privacy-footer';

import AcceptTermsText from '../AcceptTermsText';
import './QuickLogin.scss';

import { cancelMobile, pushMobile } from './index';

const { internationalMobilePhone } = FormBuilder.validators;

const { block, element } = bem('QuickLogin');

const label = t_prefixed('quick_login');

const fieldLabel = t_prefixed('users.fields');
const valuesSelector = getFormValues('quicklogin');

const QuickLogin = reduxForm({
  form: 'quicklogin',
})((props) => {
  const { isNeedCheckbox, isMiniApp, step } = props;

  const [isChecked, setChecked] = useState(!isNeedCheckbox);

  const onEnterPress = (evt) => {
    if (evt.key === 'Enter' && !(props as any).invalid && isChecked) {
      (props as any).submit();
    }
  };

  const renderSteps = () => {
    return <div {...element('steps')}>{t('issues.steps', { step, totalSteps: step })}</div>;
  };

  const handleChangeCheckbox = () => {
    setChecked(!isChecked);
  };

  return (
    <div {...block()} onKeyDown={(evt) => onEnterPress(evt)}>
      {isMiniApp && renderSteps()}

      <FormBuilder.Tel
        adornment={(props as any).invalid ? undefined : 'check_circle'}
        required
        withoutText
        props={{ autoFocus: true }}
        name="mobile"
        title={fieldLabel('mobile')}
        autofocus
        fullWidth
        validate={[internationalMobilePhone()]}
        hint={t('login.hint')}
      />

      {isNeedCheckbox && (
        <PrivacyFooter isChecked={isChecked} handleChangeCheckbox={handleChangeCheckbox} />
      )}

      <Button
        {...element('submitButton')}
        disabled={(props as any).invalid || !isChecked}
        onClick={(props as any).submit}
      >
        {label('submit')}
      </Button>

      {isMiniApp && (
        <Button
          {...element('cancelButton')}
          onClick={() => (props as any).dispatch(closeModal('miniAppQuickLogin'))}
        >
          {t('common.back')}
        </Button>
      )}

      {!isNeedCheckbox && <AcceptTermsText type="quick_login" isMiniApp={isMiniApp} />}
    </div>
  );
});

const mapStateToProps = (state) => ({
  isCodeSent: state.login.isCodeSent,
  nextTry: state.login.repeatCode,
  mobileNumber: state.login.mobileNumber,
  formValues: valuesSelector(state),
});

const mapDispatchToProps = {
  values: pushMobile,
  close: cancelMobile,
};

const QuickLoginContainer = ({
  showLogo = true,
  close,
  dispatch,
  isMiniApp,
  isCodeSent,
  mobileNumber,
  formValues,
  issueStateHandler,
  step,
  changeStep,
  isNeedCheckbox = false,
}) => {
  const showCaptchaModal = useCaptchaModal();

  const handleSubmit = (values) => {
    showCaptchaModal().then((captcha: CaptchaModel) => {
      users.pushConfirmationCode({
        mobile: (values.mobile || '').replace(/^(8)/, '+7').replace(/[^+\d]/g, ''),
        action: 'confirm_mobile',
        captcha_uuid: captcha.uuid,
        captcha_data: captcha.data,
      });
    });
  };

  const handleClose = () => {
    close();
    changeStep(step - 1);
  };

  const handleCodeConfirmation = async ({ data }) => {
    const quickLoginPayload = { ...data };
    if (!isEmpty(quickLoginPayload)) {
      try {
        delete quickLoginPayload.action;
        (users as any).quickLogin.request(quickLoginPayload).then((resp) => {
          if (isMiniApp && resp.httpStatusCode === 200) {
            issueStateHandler(true);
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const currentView = !isCodeSent ? (
    <QuickLogin
      onSubmit={handleSubmit}
      isMiniApp={isMiniApp}
      initialValues={{ mobile: mobileNumber }}
      formValues={formValues}
      step={step + 1}
      isNeedCheckbox={isNeedCheckbox}
    />
  ) : (
    <ConfirmCodePage
      onClose={handleClose}
      onSubmit={handleCodeConfirmation}
      isMiniApp={isMiniApp}
      showCaptchaModal={showCaptchaModal}
      step={step + 1}
    />
  );

  const wrapDivProps = isMiniApp ? {} : element('wrap');

  return (
    <div {...block()}>
      <div {...wrapDivProps}>
        <div {...element('container')}>
          {showLogo && <Logo inverted />}
          {isMiniApp && <Typography {...element('heading')}>{t('quick_login.auth')}</Typography>}
          {currentView}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickLoginContainer);
