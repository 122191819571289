import { UpdateActivityDataSchema } from 'shared/api/v4/swagger/data-contracts';

import { ActivitySettingsFormData, Params } from './types';

export const mapActivitySettingsToForm = ({
  name = '',
  icon_url = '',
  type = '',
  params = {},
  before_id = 0,
  managed_object_types = [],
  managed_objects = [],
}: UpdateActivityDataSchema): ActivitySettingsFormData => ({
  name,
  icon_url,
  type,
  params,
  before_id,
  managed_object_types,
  managed_objects,
  url: (params as Params).open_url?.url,
});
