import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import 'core-js/stable';
import { createRoot } from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import App from 'app/app';
import { store } from 'config/redux';
import i18n from 'lib/i18n/i18n';
import { registerFcmSW } from 'lib/service-workers/firebaseMessaging';
import { AuthProvider, QueryClientProvider } from 'app/providers';
import { TeoremaBookingScheduler } from 'app/bookings/TeoremaBookingScheduler';
import { InOutCheckin } from 'pages/in-out-checkin';

import AppThemeProvider from './lib/ui/AppThemeProvider';
import brandedData from './config/brandedData';
import './index.scss';

document.title = brandedData.applicationName;

export const getStore = () => store;

registerFcmSW();

const currentPathname = window.location.pathname;

const root = createRoot(document.getElementById('main')!);

if (currentPathname.includes('booking_scheduler')) {
  root.render(<TeoremaBookingScheduler path={currentPathname} />);
} else if (currentPathname.includes('in_out_check_in')) {
  root.render(<InOutCheckin searchParams={window.location.search} />);
} else {
  root.render(
    <QueryClientProvider>
      <StyledEngineProvider injectFirst>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <AppThemeProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </LocalizationProvider>
            </AppThemeProvider>
          </Provider>
        </I18nextProvider>
      </StyledEngineProvider>
    </QueryClientProvider>,
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (module.hot) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  module.hot.accept();
}
