import { Company, ManagementCompany } from 'lib/api/commonModels';
import { ManagedObject } from 'app/managedObjects/types';

export interface WorkingHours {
  1: string[];
  2: string[];
  3: string[];
  4: string[];
  5: string[];
  6: string[];
  7: string[];
}

export interface UserModel {
  anonymous: boolean;
  business_center: ManagedObject;
  business_center_id: number;
  city?: string;
  company: Company;
  company_id: number;
  confirmed: boolean;
  created: Date;
  deleted: boolean;
  device_id?: string;
  email?: string;
  external_links?: ExternalLinks[];
  telegram_subscription_link?: string;
  full_name: string;
  has_password: boolean;
  id: number;
  isGuest?: boolean;
  isResponsibleForInventory?: boolean;
  is_email_confirmed?: boolean;
  locations: number[];
  managed_locations: number[];
  management_company: ManagementCompany;
  management_company_id: number;
  mobile: string;
  name: string;
  notifications: string[];
  owned_locations: ManagedObject[];
  patronymic: string;
  permissions: {
    bookings: boolean;
  };
  photo: {
    url: string;
  };
  photo_id?: number;
  position?: string;
  role_arguments: any;
  roles: string[];
  reports?: unknown[];
  surname: string;
  temporary: boolean;
  uid?: any;
  updated: Date;
  web_configuration: WebConfiguration;
  working_hours: WorkingHours;
}

export enum EmployeePermissions {
  GUEST = 'guest',
  ASSETS = 'assets',
  ELEVATOR = 'elevator',
  REPRESENTATIVE = 'representative',
}

export interface UserTeoremaACS {
  id: string;
  first_name: string;
  last_name: string;
  middle_name?: string;
  mobile: string;
  company: string;
  permissions: Record<EmployeePermissions, boolean>;
}

export enum EmployeeStatus {
  VERY_BUSY = 'very_busy',
  BUSY = 'busy',
  AVAILABLE = 'available',
}

interface ExternalLinks {
  link: string;
  uuid: string;
  name: string;
  parent_menu_item: string | null;
}

interface WebConfiguration {
  cabinet: Cabinet;
}

interface Cabinet {
  lists: List[];
}

interface List {
  id: string;
  title: Title;
}

interface Title {
  ru: string;
  en: string;
  da: string;
}
