import Popover from '@mui/material/Popover';

import { IconAsButton } from 'lib/ui/icon';
import { bem } from 'lib/bem';

import './PopoverWithArrow.scss';

const { block, element } = bem('PopoverWithArrow');

interface Props {
  id?: string | undefined;
  open: boolean;
  children?: React.ReactNode;
  anchorEl?: Element | (() => Element) | null | undefined;
  onClose: () => void;
}

export const PopoverWithArrow = ({ id, open, anchorEl, onClose, children }: Props) => {
  return (
    <div className={block().className}>
      <Popover
        className={element('root').className}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div className={element('wrapper').className}>
          <div className={element('content').className}>
            <IconAsButton
              className={element('close').className}
              onClick={onClose}
              glyph="new_close"
            />
            {children}
          </div>
        </div>
      </Popover>
    </div>
  );
};
