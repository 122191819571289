import { useEffect, useState } from 'react';

import { api } from 'shared/api/api-methods';
import { Icon } from 'lib/ui/icon';
import { bem } from 'lib/bem';
import { t_prefixed } from 'lib/i18n';

import './InOutCheckin.scss';

const { block, element } = bem('InOutCheckin');
const label = t_prefixed('checkins.in_out_checkin');

const clearUrlParams = (type: string) => {
  const params = new URLSearchParams();
  params.set('type', type);
  window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
};

export const InOutCheckin = ({ searchParams }: { searchParams: string }) => {
  const params = new URLSearchParams(searchParams);
  const [status, setStatus] = useState<'success' | 'failed' | undefined>();

  const location_uuid = params.get('location_uuid');
  const type = params.get('type') as 'in' | 'out';
  const token = params.get('auth_token');

  useEffect(() => {
    api
      .post('v3/check_ins/', {
        body: { location_uuid, type },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        setStatus('success');
      })
      .catch(() => {
        setStatus('failed');
      })
      .finally(() => {
        clearUrlParams(type);
      });
  }, [type, location_uuid, token]);

  if (!status) return null;

  return (
    <div className={block().className}>
      <div className={element('content').className}>
        <Icon glyph={`in_out_checkin_${status}`} className={element('icon').className} />
        <p className={element('title').className}>{label(`${type}.${status}`)}</p>
        <p className={element('subtitle').className}>{label(`subtitle.${status}`)}</p>
      </div>
    </div>
  );
};
