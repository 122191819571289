import './LinkedIssueTypesList.scss';

export const LinkedIssueTypesList = (props) => {
  const { content } = props;
  return (
    <ul className="LinkedIssueTypesList">
      {content.map((elem, index) => (
        <li key={index}>{elem.name}</li>
      ))}
    </ul>
  );
};
