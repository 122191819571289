import { mapValues } from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Action, combineReducers } from 'redux';

import { getActions, httpError, resourceAction } from 'lib/api/resourceAction';

import createScopeReducer from './createScopeReducer';
import ResourceScope from './ResourceScope';
import { getStore } from '../../index';
import { notify } from '../../app/notifications';

function setOutdated(state) {
  return {
    ...mapValues(state, (scope) => ({
      ...scope,
      data: mapValues(scope.data, (response) => ({
        ...response,
        outdated: true,
        failed: false,
        fetching: false,
      })),
    })),
  };
}

export default class Resource {
  static defaultActions = ['create', 'update', 'remove'];

  defaultState: any;

  invalidate: any;

  name: any;

  options: any;

  reducer: any;

  saga: any;

  scopes: any;

  constructor(name, options) {
    this.name = name;
    this.options = options;
    this.scopes = this._getScopes();
    this.defaultState = this._getDefaultState();
    this.reducer = this._createReducer();
    this.saga = this._getSaga();

    this._registerActions();
    this._registerScopeSelectors();
  }

  _getMutatorRequest = (name) => {
    const { mutators } = this.options;
    const mutator = mutators[name];

    const isPlainFn = mutator.request === undefined;

    if (isPlainFn) {
      return mutator;
    }

    return mutator.request;
  };

  _getSaga() {
    const defaultActions = this._getDefaultActions();
    const { scopes, options, _getMutatorRequest } = this;
    const invalidateAction = {
      type: this._getActionName('_invalidate'),
    };

    return function* () {
      for (const action in defaultActions) {
        const actionObject = defaultActions[action];

        yield takeEvery(actionObject.type, function* (payload) {
          yield call(resourceAction, actionObject.type, _getMutatorRequest(action), payload);
        });

        yield takeEvery(`${actionObject.type}_SUCCESS`, function* (payload: any) {
          const { onSuccess } = options.mutators[action];
          if (onSuccess) {
            yield call(onSuccess);
          }
          yield put(invalidateAction);
          if (
            payload.type !== 'ISSUES/CREATE_SUCCESS' &&
            payload &&
            payload.response &&
            payload.response.metadata &&
            payload.response.metadata.message
          ) {
            yield put(
              notify({
                id: `${actionObject.type}_SUCCESS`,
                text: payload.response.metadata.message.title,
              }),
            );
          }
        });
      }

      for (const scopeName in scopes) {
        const scope = scopes[scopeName];

        yield takeEvery(scope.actionName, function* (payload) {
          yield call(resourceAction, scope.actionName, scope.request, payload);
        });
      }
    };
  }

  _getActionName(type) {
    return `${this.name.toUpperCase()}/${type.toUpperCase()}`;
  }

  _getScopes() {
    const scopes = {};

    for (const scope in this.options.scopes) {
      scopes[scope] = new ResourceScope(this, scope, this.options.scopes[scope]);
    }

    if (this.options.byId) {
      (scopes as any).byId = new ResourceScope(this, 'byId', this.options.byId, false);
    }

    return scopes;
  }

  _getDefaultState() {
    const state = {};

    for (const scopeName in this.scopes) {
      state[scopeName] = {
        data: {},
        fetching: false,
        outdated: false,
      };
    }

    return state;
  }

  _getDefaultActions() {
    const actions = {};
    const mutators = Object.keys(this.options.mutators || {});

    mutators.forEach((type) => {
      actions[type] = {
        type: this._getActionName(type),
      };
    });

    return actions;
  }

  _registerActions() {
    const actions = this._getDefaultActions();

    this.invalidate = () =>
      getStore().dispatch({
        type: this._getActionName('_invalidate'),
      });

    for (const action in actions) {
      this[action] = (payload) =>
        getStore().dispatch({
          ...actions[action],
          payload,
        });

      const actionName = this._getActionName(action);
      const dispatchActions = getActions(actionName);

      this[action].request = (payload) => {
        const requestAction = { type: actionName, payload };
        return this._getMutatorRequest(action)({ payload }).then((data) => {
          const { response, error, total, request } = data;
          if (response) {
            getStore().dispatch(
              dispatchActions.success({
                request,
                response,
                total,
                requestAction,
              }),
            );
          } else {
            getStore().dispatch(httpError(error, actionName));
            getStore().dispatch(
              dispatchActions.fail({
                request,
                error,
                requestAction,
                response,
              }),
            );
          }
          return data;
        });
      };

      this[action].actionType = actions[action].type;
    }
  }

  _registerScopeSelectors() {
    for (const scopeName in this.scopes) {
      const scope = this.scopes[scopeName];
      this[scopeName] = scope.createSelector();
    }
  }

  _createReducer() {
    const scopesReducer = combineReducers(mapValues(this.scopes, createScopeReducer));

    // eslint-disable-next-line default-param-last
    return (state = this.defaultState, action) => {
      if (action.type === this._getActionName('_invalidate')) {
        return setOutdated(state);
      }

      return scopesReducer(state, action);
    };
  }
}
