import { isNil, orderBy, isArray } from 'lodash';

import { t } from 'lib/i18n';
import FileSelect from 'lib/ui/FileSelect';
import device from 'lib/device';
import PhotoPreviewList from 'app/photos/PhotoPreviewList';
import PhotoUploader from 'app/photos/PhotoUploader';
import DocumentUploader from 'app/documents/DocumentUploader';
import DocumentPreviewList from 'app/documents/DocumentPreviewList';
import InventoryAttributeView from 'app/inventories/InventoryAttributeView';
import { FormBuilder } from 'lib/ui';
import { regexValidator } from 'lib/ui/FormBuilder/validators';

const getNumericFracSize = (attr) => {
  if (attr.type === 'uint') {
    return 0;
  }

  if (attr.type === 'decimal') {
    const { exp } = attr.options || {};
    return isNil(exp) ? 2 : Number(exp);
  }

  return 2;
};

const numericInput = (Component) => (props) => {
  const options = props.data.options || {};
  const { min, max } = FormBuilder.validators;
  const fracSize = getNumericFracSize(props.data);

  return (
    <Component
      {...props}
      minFracSize={fracSize}
      maxFracSize={fracSize}
      min={options.min}
      max={options.max}
      validate={[min(options.min), max(options.max)]}
      multiplier={10 ** fracSize}
    />
  );
};

const normalizeOptions = (options) =>
  options.map((i, index) => ({
    value: index,
    label: i.value || i,
  }));

const makeArray = (value) => (isArray(value) ? value : value ? [value] : []);

export default {
  mobile: FormBuilder.TelOld,

  date: (props) => <FormBuilder.DateTime {...props} />,

  date_only: (props) => {
    const { options } = props.data;

    return (
      <FormBuilder.Calendar
        {...props}
        startIcon="event"
        width="230px"
        fromNow={options?.from_now}
      />
    );
  },

  time_only: (props) => {
    const { options } = props.data;
    let tooltipText = '';

    if (options?.min_time) {
      tooltipText += `${t('time_picker.interval.from')} ${options.min_time} `;
    }

    if (options?.max_time) {
      tooltipText += `${t('time_picker.interval.to')} ${options.max_time} `;
    }

    return (
      <FormBuilder.Time
        {...props}
        tooltipText={tooltipText}
        minTime={options?.min_time}
        maxTime={options.max_time}
        width="230px"
      />
    );
  },

  checkbox: FormBuilder.YesNo,

  photo: (props) => {
    const attr = props.data;
    const multiple = props.data.max_count !== 1;
    const { minLength, maxLength } = (FormBuilder as any).validators;
    const nullifyEmptyArray = (v) => (v && v.length > 0 ? v : null);
    const singleValueToArray = (v) => (v ? [v] : []);

    const arrayToSingleValue = (v) => (v.length > 0 ? v[0] : null);

    const disableGallery = (attr.options || {}).disable_gallery;

    const validators = multiple ? [minLength(attr.min_count || 0), maxLength(attr.max_count)] : [];

    return (
      <div className="AttributesForm__photo">
        <FileSelect
          {...props}
          isPhoto
          uploaderText={t('photo_uploader.button_text')}
          multiple={multiple}
          normalize={multiple ? nullifyEmptyArray : arrayToSingleValue}
          format={multiple ? null : singleValueToArray}
          validate={validators}
          minCount={attr.min_count}
          maxCount={disableGallery && device.is.desktop ? 0 : attr.max_count}
          uploaderComponent={PhotoUploader}
          disableGallery={disableGallery}
          filePreviewList={(props, onRemove) => (
            <PhotoPreviewList
              photoIds={props.value || []}
              removable={!props.readonly}
              onRemove={onRemove}
            />
          )}
        />

        {disableGallery && device.is.desktop && (
          <div className="AttributesForm__photoText">
            {props.required && !attr.value
              ? t('attributes.notifications.photo.need_value')
              : t('attributes.notifications.photo.need_mobile_app')}
          </div>
        )}
      </div>
    );
  },

  document: (props) => {
    const attr = props.data;
    const multiple = props.data.max_count !== 1;
    const { minLength, maxLength } = (FormBuilder as any).validators;
    const nullifyEmptyArray = (v) => (v && v.length > 0 ? v : null);
    const singleValueToArray = (v) => (v ? [v] : []);

    const arrayToSingleValue = (v) => (v.length > 0 ? v[0] : null);

    const validators = multiple ? [minLength(attr.min_count || 0), maxLength(attr.max_count)] : [];

    return (
      <div className="AttributesForm__document">
        <FileSelect
          {...props}
          uploaderText={t('document_uploader.button_text')}
          multiple={multiple}
          normalize={multiple ? nullifyEmptyArray : arrayToSingleValue}
          format={multiple ? null : singleValueToArray}
          validate={validators}
          minCount={attr.min_count}
          maxCount={attr.max_count}
          uploaderComponent={DocumentUploader}
          filePreviewList={(props, onRemove) => (
            <DocumentPreviewList
              docIds={makeArray(props.value)}
              removable={!props.readonly}
              onRemove={onRemove}
            />
          )}
        />
      </div>
    );
  },

  full_name: (props) => {
    const options = props.data.options || {};
    return <FormBuilder.Text {...props} maxLength={options.max} />;
  },

  comment: (props) => {
    const options = props.data.options || {};
    return <FormBuilder.Textarea {...props} maxLength={options.max} />;
  },

  singleline_comment: (props) => {
    const options = props.data.options || {};
    const { minLength } = FormBuilder.validators;
    const validators = options.min
      ? [minLength(options.min), regexValidator(options.regex)]
      : [regexValidator(options.regex)];
    return <FormBuilder.Text {...props} maxLength={options.max} validate={validators} />;
  },

  numeric: numericInput(FormBuilder.Number),
  currency: numericInput(FormBuilder.Currency),
  numeric_decimal: numericInput(FormBuilder.Number),

  radio_group: (props) => {
    const options = normalizeOptions(props.data.values);

    return (
      <FormBuilder.RadioButtons
        {...props}
        options={options}
        normalize={normalizeRadioGroupValue}
        defaultValue={props.data.default_value}
      />
    );
  },

  checkbox_group: (props) => {
    const options = normalizeOptions(props.data.values);

    const { maxLength } = (FormBuilder as any).validators;

    const validators = props.data.max_count ? [maxLength(props.data.max_count)] : [];

    return (
      <FormBuilder.CheckboxGroup
        {...props}
        options={options}
        normalize={normalizeCheckboxGroupValue}
        validate={validators}
      />
    );
  },

  rating: (props) => {
    const options = props.data.options || {};
    // return <FormBuilder.Rating {...props} starsCount={options.max} />;
    return <FormBuilder.Rating {...props} starsCount={options.max || 5} />;
  },

  inventory_audit() {
    return null;
  },

  inventory_item: (props) => (
    <FormBuilder.Custom
      {...props}
      inventoryId={props.data.value}
      component={InventoryAttributeView}
    />
  ),

  select: (props) => {
    const { name, title, required, data } = props;

    const options = normalizeOptions(data.values);

    const getItemValue = (option) => {
      return option.value;
    };

    return (
      <FormBuilder.Select
        searchable
        clearable
        name={name}
        title={title}
        required={required}
        options={options}
        getItemValue={getItemValue}
      />
    );
  },

  time_slots: ({ data, ...rest }) => {
    return <FormBuilder.BookingTimeSlots data={data} {...rest} />;
  },

  time_slots_by_days: ({ data, ...rest }) => {
    return <FormBuilder.BookingDaySlots data={data} {...rest} />;
  },

  sharing_slots_by_days: ({ data, ...rest }) => {
    return <FormBuilder.SharingDaySlots data={data} {...rest} />;
  },

  geolocation_verification: ({ data, ...rest }) => {
    return <FormBuilder.Geolocation data={data} {...rest} />;
  },

  label: () => null,

  qrcode_verification: ({ data, ...rest }) => {
    return <FormBuilder.QRcodeVerify data={data} {...rest} />;
  },

  photo_readonly: () => null,

  document_readonly: () => null,
};

function normalizeRadioGroupValue(value) {
  return isNil(value) ? null : Number(value);
}

function normalizeCheckboxGroupValue(value) {
  return (value || []).length === 0 ? null : orderBy(value);
}
