import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ScrollLock from 'react-scroll-lock-component';
import { isEmpty, isFunction, uniqBy, keyBy, times, flatten, flow } from 'lodash';
import { Box, InputAdornment } from '@mui/material';

import { reconnect } from 'lib/resource';
import { bem } from 'lib/bem';
import { Search } from 'lib/ui';
import { Icon, UrlIcon } from 'lib/ui/icon';
import { t } from 'lib/i18n';
import { Avatar } from 'lib/ui/Avatar';

import './SimpleSelect.scss';
import SelectMenu from './SelectMenu';
import SelectValue from './SelectValue';
import { useClickOutside } from '../../hooks/useClickOutside';
import { getEmploymentIndicator } from './getEmploymentIndicator';
import { ValueDisplayConfigProps } from './types';
import { TextButton } from 'shared/ui/text-button';

const { block, element } = bem('SimpleSelect');

const SelectContainer = (props) => {
  const [search, setSearch] = useState('');
  const [visiblePages, setVisiblePages] = useState(1);

  const handleSearch = useCallback((value) => {
    setSearch(value);
  }, []);

  const handleRequestMore = useCallback(() => {
    setVisiblePages(visiblePages + 1);
  }, [visiblePages]);

  return (
    <SimpleSelect
      {...props}
      search={search}
      visiblePages={visiblePages}
      onSearch={handleSearch}
      onRequestMore={handleRequestMore}
    />
  );
};

const defaultSelectProps = {
  multiple: false,
  searchable: false,
  closeOnSelect: true,
  placeholder: t('common.select_placeholder'),
  getItemValue: (item) => item && item.value,
  getItemLabel: (item) => item && item.label,
  onChangeCallback: (item) => {},
  connectResource: null,
  modifyOptions: (options, search) => options,
  filterOptions: (options) => options,
  className: '',
};

const SimpleSelect = flow(
  memo,
  reconnect((state, props) => {
    if (!props.connectResource) return {};

    const result = {};

    times(props.visiblePages, (page) => {
      result[`resource_${page}`] = props.connectResource(state, props.search, page);
    });

    return {
      nestedSelectors: result,
    };
  }),
)(({
  options,
  connectResource,
  getItemValue,
  getItemLabel,
  modifyOptions,
  multiple,
  searchable,
  closeOnSelect,
  isLoading,
  maxSearchLength,
  className,
  search = '',
  visiblePages,
  value,
  filterOptions,
  excludedIds,
  inputClassName,
  onSearch,
  blur,
  focus,
  onChange,
  placeholder,
  placeholderStyle,
  labelModifiers,
  iconClassName,
  name,
  addable,
  onRequestMore,
  onChangeCallback,
  nestedSelectors,
  getIconStyles,
  clearable,
  valueDisplayConfig,
}) => {
  const getResourcePages = useCallback(() => {
    if (!nestedSelectors) {
      return [];
    }

    const result: any[] = [];

    times(visiblePages, (pageIndex) => {
      const page = nestedSelectors[`resource_${pageIndex}`];
      result.push(page && page.result && page.result.content.data);
    });

    return result;
  }, [nestedSelectors, visiblePages]);

  const allOptions = useMemo(() => {
    let result;

    if (connectResource) {
      result = flatten(getResourcePages().filter(Boolean));
    } else {
      result = (isFunction(options) ? options(search) : options) || [];
    }

    const matchedOptions = result.filter((option) =>
      getItemLabel(option).toLowerCase().includes(search.trim().toLowerCase()),
    );
    return modifyOptions(filterOptions(matchedOptions), search);
  }, [
    connectResource,
    filterOptions,
    getItemLabel,
    getResourcePages,
    modifyOptions,
    options,
    search,
  ]);

  const [optionsIndex, setOptionsIndex] = useState({});
  const [hiddenOptionsIndex, setHiddenOptionsIndex] = useState({});
  const [visibleOptions, setVisibleOptions] = useState<any[]>([]);
  const [failedImages, setFailedImages] = useState<any[]>([]);

  const handleFailedImgLoad = useCallback(
    (e) => {
      const newFailedImages = failedImages.slice();
      if (!newFailedImages.includes(e.target.src)) {
        newFailedImages.push(e.target.src);
      }
      setFailedImages(newFailedImages);
    },
    [failedImages],
  );

  const collectOptions = useCallback(() => {
    const valuesIndex = multiple ? keyBy(value || [], (item) => getItemValue(item) || item) : {};
    let filteredOptions = allOptions
      .filter((option) => {
        if (excludedIds && excludedIds.length) {
          return !excludedIds.includes(option.id);
        }
        return true;
      })
      .filter((option) => !(getItemValue(option) in valuesIndex));

    const needFilterFailedImages = filteredOptions.some((x) => x.img);
    if (needFilterFailedImages) {
      const hiddenOptions = filteredOptions.filter((x) => failedImages.includes(x.value));
      setHiddenOptionsIndex(keyBy(hiddenOptions, getItemValue));
      filteredOptions = filteredOptions.filter((option) => {
        if (failedImages && failedImages.length) {
          return !failedImages.includes(option.value);
        }
        return true;
      });
    }

    setVisibleOptions(filteredOptions);
    setOptionsIndex(keyBy(allOptions, getItemValue));
  }, [allOptions, excludedIds, failedImages, getItemValue, multiple, value]);

  useEffect(() => {
    collectOptions();
  }, [allOptions.length, collectOptions]);

  const [isFocused, setFocused] = useState(false);
  const searchInput = useRef<any>(null);

  const keepSearchFocused = useCallback(() => {
    if (searchInput.current) {
      searchInput.current?.focus();
    }
  }, []);

  const safeCallbackCall = useCallback(
    (fnName, ...args) => {
      const callback = {
        blur,
        focus,
        onChange,
      }[fnName];

      if (isFunction(callback)) {
        return callback(...args);
      }
    },
    [blur, focus, onChange],
  );

  const handleBlur = useCallback(() => {
    setFocused(false);
    onSearch('');
    safeCallbackCall('blur');
  }, [onSearch, safeCallbackCall]);

  const handleFocus = useCallback(() => {
    setFocused(true);
    safeCallbackCall('focus');
  }, [safeCallbackCall]);

  const toggleFocus = useCallback(() => {
    if (isFocused) {
      handleBlur();
    } else {
      handleFocus();
    }
  }, [handleBlur, handleFocus, isFocused]);

  const renderLabel = useCallback(
    (item, itemDisplayConfig: ValueDisplayConfigProps) => {
      return (
        <div
          {...element(
            'option_content',
            labelModifiers,
            itemDisplayConfig?.isShowIndicator ? getEmploymentIndicator(item.state) : undefined,
          )}
        >
          {item && item.img && (
            <UrlIcon
              sx={getIconStyles}
              onImgSrcError={handleFailedImgLoad}
              {...element('icon', {}, iconClassName)}
              url={item.img}
            />
          )}

          {item && itemDisplayConfig?.isShowAvatar && (
            <div {...element('avatar')}>
              <Avatar
                url={item.photo?.url ? item.photo?.url : null}
                border
                width="25px"
                height="25px"
              />
            </div>
          )}

          <div
            {...element(
              'label',
              itemDisplayConfig?.isShowFavorite && item.favorite && { favorite: true },
            )}
          >
            {getItemLabel(item)}
          </div>
        </div>
      );
    },
    [getIconStyles, getItemLabel, handleFailedImgLoad, iconClassName, labelModifiers],
  );

  const checkIfLoading = useCallback(() => {
    if (connectResource) {
      return !getResourcePages().pop();
    }

    return isLoading;
  }, [connectResource, getResourcePages, isLoading]);

  const handleRemove = useCallback(
    (option) => {
      safeCallbackCall(
        'onChange',
        (value || []).filter(
          (item) => item !== option && getItemValue(item) !== getItemValue(option),
        ),
      );
      onChangeCallback(value);
    },
    [getItemValue, onChangeCallback, safeCallbackCall, value],
  );

  const handleChangeSearch = useCallback(
    (e) => {
      onSearch(e.target.value);
    },
    [onSearch],
  );

  const clearSearch = useCallback(() => {
    onSearch('');
    searchInput.current.focus();
  }, [onSearch]);

  const handleSelect = useCallback(
    (option) => {
      if (multiple) {
        safeCallbackCall('onChange', uniqBy([...(value || []), option], getItemValue));
        onSearch('');
      } else {
        onChangeCallback(option);
        safeCallbackCall('onChange', option);

        if (closeOnSelect) {
          handleBlur();
        }
      }
    },
    [
      closeOnSelect,
      getItemValue,
      handleBlur,
      multiple,
      onChangeCallback,
      onSearch,
      safeCallbackCall,
      value,
    ],
  );

  const handleClear = useCallback(() => {
    onChangeCallback([]);
    safeCallbackCall('onChange', []);
  }, [onChangeCallback, safeCallbackCall]);

  const hasMore = useMemo(() => {
    const lastPage = getResourcePages().pop();
    return !lastPage || lastPage.length > 0;
  }, [getResourcePages]);

  const handleRequestMore = useCallback(() => {
    if (!checkIfLoading() && hasMore) {
      onRequestMore();
    }
  }, [checkIfLoading, hasMore, onRequestMore]);

  const handleClickOutside = useCallback(() => {
    handleBlur();
  }, [handleBlur]);

  const { ref } = useClickOutside(handleClickOutside);

  const inputWrapperRef = useRef<any>(null);

  return (
    <Box {...block()}>
      {multiple && !!value?.length && (
        <div {...element('clear')}>
          <TextButton onClick={handleClear} color="primary" isUnderline>
            {t('common.clear')}
          </TextButton>
        </div>
      )}
      <Box ref={ref} {...element('wrapper', { multiple }, className)} onClick={keepSearchFocused}>
        <Box
          ref={inputWrapperRef}
          sx={isFocused ? { border: ({ palette }) => `1px solid ${palette.active}` } : undefined}
          {...element('input', {}, inputClassName)}
          onMouseDown={toggleFocus}
        >
          <SelectValue
            hiddenOptionsIndex={hiddenOptionsIndex}
            multiple={multiple}
            placeholder={placeholder}
            placeholderStyle={placeholderStyle}
            value={value}
            optionsIndex={optionsIndex}
            getItemValue={getItemValue}
            renderLabel={renderLabel}
            isLoading={checkIfLoading()}
            onRemove={handleRemove}
            visibleOptions={visibleOptions || []}
            selectName={name}
            addable={addable}
          />
          {(multiple || !value || !clearable) && !isEmpty(optionsIndex) && (
            <Icon glyph="squareArrow" {...element('arrow', { isFocused })} />
          )}
        </Box>

        {isFocused && (
          <Box
            sx={inputWrapperRef.current && { width: `${inputWrapperRef.current?.clientWidth}px` }}
            {...element('dropdown')}
          >
            {searchable && (
              <Search
                autoFocus
                className={{ ...element('searchWrapper') }.className}
                inputClassName={{ ...element('search') }.className}
                inputRef={searchInput}
                onChange={handleChangeSearch}
                maxLength={maxSearchLength}
                invisibleFocus
                value={search}
                iconGlyph={search ? 'close' : 'search'}
                adornment={
                  <InputAdornment position="end">
                    <Icon glyph={search ? 'close' : 'search'} onClick={clearSearch} />
                  </InputAdornment>
                }
              />
            )}

            {visibleOptions.length > 0 && (
              <ScrollLock>
                <SelectMenu
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ options: any[]; renderLabel: (item: any) =... Remove this comment to see the full error message
                  options={visibleOptions}
                  renderLabel={renderLabel}
                  getItemValue={getItemValue}
                  isLoading={checkIfLoading()}
                  onSelect={handleSelect}
                  onCancel={handleBlur}
                  onScrollThrough={connectResource && handleRequestMore}
                  valueDisplayConfig={valueDisplayConfig}
                />
              </ScrollLock>
            )}

            {visibleOptions.length === 0 && (
              <div {...element('noResults')}>
                {searchable && !search ? t('common.type_to_search') : t('common.no_results')}
              </div>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
});

SimpleSelect.defaultProps = defaultSelectProps;

export default memo(SelectContainer);
