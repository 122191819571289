import { ReactNode, useCallback, useState } from 'react';
import { Tab, Tabs, Typography } from '@mui/material';

// eslint-disable-next-line import/no-cycle
import QuickLogin from 'app/entry/QuickLogin';
import { Logo } from 'lib/ui';
import { brandedThemeOptions } from 'lib/ui/AppThemeProvider/theme';
import { bem } from 'lib/bem';
import brandedData from 'config/brandedData';

import Login from '../Login';
import './Entry.scss';

const { block, element } = bem('Entry');

type OwnProps = {
  footer: ReactNode;
  showLogo?: boolean;
};

export const Entry = ({ footer }: OwnProps) => {
  const [tabValue, setTabValue] = useState('1');
  const [step, setStep] = useState(1);

  const handleChangeStep = useCallback((value: number) => {
    setStep(value);
  }, []);

  const handleChangeTab = (_, value: string) => {
    setTabValue(value);
  };

  return (
    <div {...block()}>
      <div
        style={{
          marginTop: '16px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <div {...element('logo')}>
          <Logo inverted />
        </div>
        <div {...element('container')}>
          <Typography {...element('heading')}>{t('login.title')}</Typography>
          {step === 1 && (
            <Tabs
              sx={{
                '.MuiTabs-indicator': {
                  backgroundColor: `${brandedThemeOptions.accentColor}`,
                },
              }}
              value={tabValue}
              onChange={handleChangeTab}
            >
              <Tab label={t('login.enter_with_sms')} value="1" />
              {brandedData.loginOptions.hasPassword && (
                <Tab label={t('login.enter_with_password')} value="2" />
              )}
            </Tabs>
          )}
          {tabValue === '1' && (
            <QuickLogin
              showLogo={false}
              dispatch={undefined}
              isMiniApp={false}
              issueStateHandler={undefined}
              step={step}
              changeStep={handleChangeStep}
              isNeedCheckbox={brandedData.loginOptions.hasCheckbox}
            />
          )}
          {tabValue === '2' && <Login isNeedCheckbox={brandedData.loginOptions.hasCheckbox} />}
        </div>
      </div>
      <div {...element('entryFooter')}>{footer}</div>
    </div>
  );
};
