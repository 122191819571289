import { lazy } from 'react';

export const NewIssueLazy = lazy(
  () => import(/* webpackChunkName: "NewIssue" */ 'app/issues/_pages/NewIssue'),
);
export const NewQRIssueLazy = lazy(
  () => import(/* webpackChunkName: "NewQRIssue" */ 'app/issues/_pages/NewQRIssue'),
);
export const NewOunceIssueLazy = lazy(
  () => import(/* webpackChunkName: "NewOunceIssue" */ 'app/issues/_pages/NewOunceIssue'),
);
export const IssueDetailsLazy = lazy(
  () => import(/* webpackChunkName: "IssueDetails" */ 'app/issues/IssueDetails'),
);
