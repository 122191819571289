import './IssueRating.scss';
import { PureComponent } from 'react';
import { Box } from '@mui/material';

import { bem } from 'lib/bem';
import users from 'app/users/users.resource';
import { Rating } from 'lib/ui';
import { reconnect } from 'lib/resource';

const { block } = bem('IssueRating');
export default reconnect((state, ownProps) => ({
  currentUser: (users as any).current(state, {}),
}))(
  class IssueRating extends PureComponent {
    isByCurrentUser() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentUser' does not exist on type 'Rea... Remove this comment to see the full error message
      const { currentUser, issue } = this.props;
      return currentUser.data.id === issue.author_id;
    }

    render() {
      const { issue, editable, showEmpty, className, short, sx } = this.props as any;
      if (!issue.review && issue.review_status !== 'rated') return null;

      const rating = issue.review?.rating ?? issue.review_rating;
      if (short) {
        if (!rating) return null;
        return (
          <Box sx={sx} {...block({ short: true }, className)}>
            <Rating starsCount={1} value={1} />
            <Box sx={{ ml: 0.5 }}>{rating}</Box>
          </Box>
        );
      }
      return (
        <Rating
          className={className}
          showEmpty={showEmpty}
          value={rating}
          editable={editable && this.isByCurrentUser()}
        />
      );
    }
  },
);
