import { useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { Button } from 'lib/ui/index';
import { t, t_prefixed } from 'lib/i18n';
import { queryClient } from 'shared/lib/react-query';
import { ApiSendResponse } from 'shared/api/types/api-methods-types';
import { RoleRelationsListResponseSchema } from 'shared/api/v4/swagger/data-contracts';
import { ConfirmModal } from 'shared/ui/confirm-modal';
import { rolesQueries, useDeleteRole } from 'entities/roles';

import { IUserRoles } from '../lib';
import { RoleRelationsList } from './RoleRelationsList';

interface Props {
  item: IUserRoles;
}

const { element } = bem('UserRoles');
const label = t_prefixed('user_roles.modals');

const UserRolesListItem = ({ item }: Props) => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const deleteMutation = useDeleteRole();

  const stopClickPropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const getRoleRelations = async (roleId) => {
    const roleRelationsResponse = await queryClient.fetchQuery(rolesQueries.roleRelations(roleId));

    const roleRelationsData =
      roleRelationsResponse as unknown as ApiSendResponse<RoleRelationsListResponseSchema>;

    if (roleRelationsData && 'response' in roleRelationsData) {
      return roleRelationsData.response?.data;
    }
    return null;
  };

  const handleOpenModal = async (roleId: number) => {
    const userRoleRelations = await getRoleRelations(roleId);
    if (userRoleRelations) {
      setModalContent(userRoleRelations);
    }
    setOpen(true);
  };

  const handleRemove = (role) => (e) => {
    handleOpenModal(role.id);
    stopClickPropagation(e);
  };

  const handleConfirm = (e) => {
    deleteMutation.mutate(Number(item.id));
    setOpen(false);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  return (
    <div>
      <ConfirmModal
        title={label('remove.title')}
        body={label('remove.question')}
        isOpen={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText={label('remove.confirm_text')}
        cancelColor="ghost"
        confirmColor="primary"
        small={false}
        onClick={stopClickPropagation}
      >
        <RoleRelationsList data={modalContent} />
      </ConfirmModal>

      <div {...element('item')}>
        <div {...element('itemInfo')}>
          <p {...element('itemName')}>
            {item.name && item.name[0].toUpperCase().concat('', item.name.slice(1))}
          </p>
          <p {...element('itemDescription')}>{item.description}</p>
        </div>
        <div {...element('itemActions')}>
          {!item.system && (
            <Button small ghost>
              {t('common.edit')}
            </Button>
          )}

          {!item.system && (
            <Button small ghost onClick={handleRemove(item)}>
              {t('common.remove')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserRolesListItem;
