import { createQueryKeys } from '@lukemorales/query-key-factory';

import { apiClient_v4 } from 'shared/api/v4/client';
import { api } from 'lib/api';

export const rolesQueries = createQueryKeys('role', {
  list: { queryKey: null, queryFn: () => api.get('/v2/roles/') },
  roleRelations: (roleId?: number) => ({
    queryKey: [roleId!],
    queryFn: () => (roleId ? apiClient_v4.roles.getRoleRelations({ roleId }) : undefined),
  }),
});
