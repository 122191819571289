import { InferType } from 'yup';

import { activitySettingsUpdateSchema } from 'entities/activities/api/resolver';

export type ActivitySettingsFormData = { url?: string } & InferType<
  typeof activitySettingsUpdateSchema
>;

export enum ActivityTypeEnum {
  CREATE_ISSUE = 'create_issue',
  OPEN_URL = 'open_url',
}

export type Params = {
  open_url?: {
    url: string;
  };
};
