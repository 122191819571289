import { Box } from '@mui/material';

import { bem } from 'lib/bem';
import brandedData from 'config/brandedData';
import { brandName } from 'config/constants';

import './Logo.scss';

const { block } = bem('Logo');

const { shortLogo } = brandedData;

interface Props {
  inverted?: boolean;
  height?: number | string;
  className?: string;
  short?: boolean;
}

export const Logo = ({ inverted, height, className, short }: Props) => {
  const logo = `/brands/${brandName}/${inverted ? 'inverted-' : ''}logo.webp`;
  const logoUrl = short ? shortLogo : logo;

  if (short && !shortLogo) {
    return null;
  }

  return (
    <Box className={block({}, className).className}>
      <img src={logoUrl} alt="logo" height={height || (short ? 32 : 48)} />
    </Box>
  );
};
