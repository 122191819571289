import Linkify from 'linkify-react';
import { isNil, isArray } from 'lodash';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { t } from 'lib/i18n';
import { Date, Rating, MonetarySign } from 'lib/ui';
import number from 'lib/number';
import PhotoPreviewList from 'app/photos/PhotoPreviewList';
import InventoryAudit from 'app/inventories/InventoryAudit/container';
import InventoryAttributeView from 'app/inventories/InventoryAttributeView';
import DocumentPreviewList from 'app/documents/DocumentPreviewList';
import { CheckboxGroup } from 'lib/ui/FormBuilder/types';
import { FormattedText } from 'lib/ui/FormattedText';

const getExp = (attr) => {
  if (attr.type === 'uint') {
    return 0;
  }

  if (attr.type === 'decimal') {
    const { exp } = attr.options || {};
    return isNil(exp) ? 2 : Number(exp);
  }

  return 2;
};

const normalizeOptions = (options) =>
  options.map((i, index) => ({
    value: index,
    label: i.value || i,
  }));

export const makeArray = (value) => (isArray(value) ? value : value ? [value] : []);

export default {
  default({ attrDescriptor, value }) {
    return <div>{value}</div>;
  },

  checkbox({ attrDescriptor, value }) {
    return <div>{value ? t('common.yes') : t('common.no')}</div>;
  },

  full_name: function FullNameAttribute({ attrDescriptor, value }) {
    return <div>{value}</div>;
  },

  currency({ attrDescriptor, value }) {
    const divideBy = 10 ** getExp(attrDescriptor);
    return (
      <div>
        {number(value / divideBy, '0.00')}
        <MonetarySign currency={attrDescriptor.options.currency} />
      </div>
    );
  },

  numeric({ attrDescriptor, value }) {
    const divideBy = 10 ** getExp(attrDescriptor);

    return <div>{number(value / divideBy)}</div>;
  },

  numeric_decimal({ attrDescriptor, value }) {
    const divideBy = 10 ** getExp(attrDescriptor);

    return <div>{number(value / divideBy, '0.0[0000]')}</div>;
  },

  photo: function PhotoAttribute({ attrDescriptor, value }) {
    return <PhotoPreviewList photoIds={value} />;
  },

  document: function PhotoAttribute({ attrDescriptor, value }) {
    return <DocumentPreviewList docIds={value} />;
  },

  comment: function CommentAttribute({ attrDescriptor, value }) {
    return (
      <Linkify as="div" options={{ rel: 'noopener noreferrer', target: '_blank', nl2br: true }}>
        {value}
      </Linkify>
    );
  },

  singleline_comment: function CommentAttribute({ attrDescriptor, value }) {
    return (
      <Linkify as="div" options={{ rel: 'noopener noreferrer', target: '_blank' }}>
        {value}
      </Linkify>
    );
  },

  mobile: function CommentAttribute({ attrDescriptor, value }) {
    return <div>{value}</div>;
  },

  date: function DateAttribute({ attrDescriptor, value }) {
    return <Date value={value} />;
  },

  date_only: function DateAttribute({ attrDescriptor, value }) {
    return <Date value={value} showTime={false} />;
  },

  time_only: function DateAttribute({ attrDescriptor, value }) {
    return <div>{value}</div>;
  },

  rating: function RatingAttribute({ attrDescriptor, value }) {
    return <Rating value={value} starsCount={(attrDescriptor.options || {}).max} />;
  },

  radio_group({ attrDescriptor, value }) {
    return <div>{attrDescriptor.values[value] || value}</div>;
  },

  checkbox_group({ attrDescriptor, value }) {
    const options = normalizeOptions(attrDescriptor.values);

    return <CheckboxGroup input={{ value }} options={options} disabled />;
  },

  signature: function PhotoAttribute({ attrDescriptor, value }) {
    return <PhotoPreviewList photoIds={value} />;
  },

  inventory_audit: ({ attrDescriptor, value }) =>
    value ? <InventoryAudit inventoryAuditId={value} /> : undefined,

  inventory_item: ({ attrDescriptor, value }) => <InventoryAttributeView inventoryId={value} />,

  select({ attrDescriptor, value }) {
    return <div>{attrDescriptor.values[value]}</div>;
  },

  label: ({ attrDescriptor, value }) => {
    const fontSize = attrDescriptor.options.font_size
      ? { fontSize: attrDescriptor.options.font_size, lineHeight: '150%' }
      : {};
    const boldText = attrDescriptor.options.bold_text ? { fontWeight: '700' } : {};
    const italicText = attrDescriptor.options.italic_text ? { fontStyle: 'italic' } : {};
    const stylesObject = { ...fontSize, ...boldText, ...italicText };
    return <FormattedText text={value} sx={stylesObject} />;
  },

  geolocation_verification: () => null,

  qrcode_verification: ({ value }) => {
    return (
      <Box sx={{ display: 'flex' }}>
        <CheckCircleIcon sx={{ color: '#34C759' }} />
        <Typography sx={{ color: '#667790', ml: 1 }}>
          {t('issues.fields.qrcode_verify.readonly_success')}
        </Typography>
      </Box>
    );
  },

  photo_readonly: ({ attrDescriptor, value }) => {
    return <PhotoPreviewList photoIds={value} removable={false} />;
  },

  document_readonly: ({ attrDescriptor, value }) => {
    return <DocumentPreviewList docIds={value} />;
  },
};
