import { intersection } from 'lodash';

import { Roles } from 'app/userRoles/roles';
import { UserModel } from 'app/users/types';
// eslint-disable-next-line import/no-cycle
import { RouteInfo } from 'config/routesMap';

function userHasOneOfRoles(user: UserModel, roles: Roles[] = []) {
  return intersection(user.roles, roles).length > 0;
}

export default {
  userHasOneOfRoles,

  userHasAccessToRoute(user: UserModel, route: RouteInfo) {
    if (route.allowedRoles.includes(Roles.CHECK_CONFIG)) {
      return true;
    }
    const { isAllowedForUser = () => true } = route;
    return userHasOneOfRoles(user, route.allowedRoles) && isAllowedForUser(user);
  },
};
