import { Box } from '@mui/system';

import { t } from 'lib/i18n';
import { ModalV2 } from 'shared/ui/modal-v2';
// eslint-disable-next-line import/no-cycle
import { Button } from 'lib/ui';

import './ConfirmModal.scss';

const { block, element } = bem('ConfirmModal');
interface Props {
  children?: React.ReactNode;
  body: string;
  title: string;
  isOpen: boolean;
  onClick?: (e) => void;
  onClose: (e) => void;
  onConfirm: (e) => void;
  confirmText?: string;
  cancelText?: string;
  confirmColor?: 'error' | 'primary' | 'ghost'; // primary changes nothing
  cancelColor?: 'primary' | 'ghost';
  small?: boolean;
}

export const ConfirmModal = ({
  children,
  title,
  body,
  isOpen,
  onClick,
  onClose,
  onConfirm,
  confirmText = t('common.ok'),
  cancelText = t('common.cancel'),
  confirmColor = 'primary',
  cancelColor = 'ghost',
  small = true,
}: Props) => {
  return (
    <ModalV2 title={title} open={isOpen} onClose={onClose} small={small} onClick={onClick}>
      <Box {...block({})}>
        <div {...element('body')}>{body}</div>
        {children && <div {...element('body')}>{children}</div>}
        <div {...element('buttons')}>
          <Button
            ghost={cancelColor === 'ghost'}
            {...element('button', { [cancelColor]: true })}
            onClick={onClose}
          >
            {cancelText}
          </Button>
          <Button
            ghost={confirmColor === 'ghost'}
            {...element('button', { [confirmColor]: true })}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        </div>
      </Box>
    </ModalV2>
  );
};
