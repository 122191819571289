import { ChangeEvent, memo, useCallback, useEffect, useMemo } from 'react';
import { Pagination as MaterialPagination, PaginationItem } from '@mui/material';

import { usePrevious } from '../../hooks/usePrevious';

interface Props {
  total?: number;
  perPage?: number;
  offset?: any;
  onPageChange: (offset: number) => any;
  routerOffset?: number;
  className?: string;
  sx?: Record<string, any>;
}

const Pagination = ({
  total = 0,
  perPage = 20,
  offset = 0,
  sx = {},
  onPageChange,
  routerOffset,
  className,
}: Props) => {
  const count = useMemo(() => Math.ceil(total / perPage), [perPage, total]);

  const currentPage = useMemo(() => {
    return Math.floor((routerOffset || offset) / perPage) + 1;
  }, [offset, perPage, routerOffset]);

  const prevRouterOffset = usePrevious(routerOffset);

  useEffect(() => {
    if (routerOffset && routerOffset !== prevRouterOffset) {
      onPageChange(routerOffset);
    }
  }, [onPageChange, prevRouterOffset, routerOffset]);

  const handleChange = useCallback(
    (event: ChangeEvent<unknown>, page: number) => {
      onPageChange((page - 1) * 20);
    },
    [onPageChange],
  );

  const renderItem = useCallback((paginationItemProps) => {
    return (
      <PaginationItem
        sx={
          paginationItemProps.selected
            ? {
                bgcolor: ({ palette }) => `${palette.primary.main} !important`,
                color: '#FFF',
              }
            : {}
        }
        {...paginationItemProps}
      />
    );
  }, []);

  if (total <= perPage) {
    return null;
  }

  return (
    <MaterialPagination
      className={`pagination${className ? ` ${className}` : ''}`}
      sx={{ ...sx, marginBottom: 2 }}
      onChange={handleChange}
      count={count}
      variant="outlined"
      shape="rounded"
      page={currentPage}
      renderItem={renderItem}
    />
  );
};

export default memo(Pagination);
