import './IssueTypeName.scss';
import { bem } from 'lib/bem';

const { block } = bem('IssueTypeName');

export default function IssueTypeName({ issue, className, ...props }) {
  return (
    <div {...block({}, className)} {...props}>
      {issue.issue_type_name || issue.issue_type?.name}
    </div>
  );
}
