import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';

import { apiClient_v4 } from 'shared/api/v4/client';

export const useDeleteRole = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async (roleId: number) => {
      await apiClient_v4.roles.deleteRole({ roleId });
      return { roleId };
    },

    // Обновление списка пока через ресурсы, в будущем надо это изменить
    onSuccess: () =>
      dispatch({
        type: 'USERROLES/_INVALIDATE',
      }),
  });
};
