import './ActivitiesList.scss';
import { useCallback, useEffect, useMemo, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { formValueSelector } from 'redux-form';
import queryString from 'query-string';
import 'scroll-shadow-element';

import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { Page, PageActions } from 'lib/ui';
import { reconnect } from 'lib/resource';
import { LocationLabel, locations } from 'app/locations';
import { isTheOunceEmployee } from 'app/users';
import users, { isCrossDomainAuth } from 'app/users/users.resource';
import { openModal } from 'lib/ui/Modal';
import NewIssueSuccessModal from 'app/miniApp/NewIssueSuccessModal';
import { ActivityModel, ActivityType } from 'app/activities/types';
import { updateQuery } from 'lib/routerUtils';
import { useMounting } from 'lib/hooks/useMounting';
import Activity from 'app/activities/Activity';
import { OnboardModal } from 'app/entry/OnboardModal';
import { useQueryParams } from 'lib/helpers/useQueryParams';
import device from 'lib/device';

import activitiesResource from '../activities.resource';
import VerifyLocation, { openVerifyLocationModal } from '../VerifyLocation';
import LocationCard from '../../locations/Locations/LocationCard/LocationCard';
import ActivitiesLocationSelect2 from '../ActivitiesLocationSelect/ActivitiesLocationSelect2';

const { block, element } = bem('ActivitiesList');
const valueSelector = formValueSelector('activitiesLocationSelect');

interface ActivityUrlHandlerProps {
  activity: ActivityModel;
  location?: Record<string, any>;
  locationIdParam?: number;
}
type ActivityUrlHandler = (props: ActivityUrlHandlerProps) => string;

const activityUrls: Record<ActivityType, ActivityUrlHandler> = {
  create_issue: ({ activity, location, locationIdParam }) => {
    const params = {
      activity: activity.id,
      location: location?.id,
    };
    if (locationIdParam) {
      delete params.location;
    }
    return `/issues/new?${queryString.stringify(params)}`;
  },
  show_details: ({ location, locationIdParam }) => {
    return locationIdParam ? '/details' : `/locations/${location?.id}/details`;
  },
  book: ({ location, locationIdParam }) => {
    return locationIdParam ? '/bookings/new' : `/locations/${location?.id}/bookings/new`;
  },
  share_booking_item: ({ location }) => {
    return `/locations/${location?.id}/share`;
  },
  open_url: ({ activity }) => {
    if (activity.params && activity.params.open_url) {
      if (activity.params.open_url.in_app) {
        return {
          pathname: window.location.pathname,
          query: {
            open_html: activity.params.open_url.url,
          },
        };
      }
      return activity.params.open_url.url;
    }
  },
};

const VALID_ACTIVITY_TYPES = Object.keys(activityUrls);

const Verification = ({ onVerify }) => {
  return (
    <div {...element('verifyLocation')}>
      <div {...element('verifyLocationLink')}>
        <span onClick={onVerify}>{t('verify_location.title')}</span>
      </div>
      <VerifyLocation />
    </div>
  );
};

const NewGroupIssueLink = () => {
  return (
    <Link {...element('groupIssueLink')} to="/issues/group/new">
      {t('issues.new_group_issue')}
    </Link>
  );
};

const ScrollShadowWrapper = ({ children }): JSX.Element => {
  return device.is.phone ? (
    <scroll-shadow class="ActivitiesList__scrollShadow">{children}</scroll-shadow>
  ) : (
    <> {children} </>
  );
};

const ActivitiesList = reconnect((state, ownProps) => {
  const location = valueSelector(state, 'location');
  const { qrCode, locationId } = ownProps.router?.params ?? {};
  const { code, fromSite, location: routerLocation } = ownProps.router?.query ?? {};

  const selectedLocation = locationId ?? routerLocation;
  const finalCode = qrCode ?? code;
  const isFromSite = !!fromSite;

  return {
    stateLocation: location,
    activities: (activitiesResource as any).byLocation(
      state,
      location ? location.id : locationId || null,
    ),
    currentUser: (users as any).current(state, {}),
    qrCodeLocation: !locationId
      ? finalCode
        ? (locations as any).tree(state, {
            code: finalCode,
            filter_management_company: 'f',
          })
        : undefined
      : undefined,
    selectedLocation: selectedLocation
      ? (locations as any).byId(state, selectedLocation)
      : undefined,
    suggestedLocations: !locationId ? (locations as any).suggested(state, {}) : undefined,
    isFromSite,
  };
})(({
  router,
  activities,
  qrCodeLocation,
  selectedLocation = {},
  suggestedLocations = {},
  stateLocation,
  currentUser,
  isFromSite,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { locationId } = useParams();
  const { query } = useQueryParams();

  const handleMounting = useCallback(() => {
    if (query.createdIssue) {
      const newLocationQuery = { ...query };
      delete newLocationQuery.createdIssue;
      updateQuery(router, newLocationQuery, true, true);
    }
    if (query.createdAnonymousIssue) {
      const newLocationQuery = { ...query };
      delete newLocationQuery.createdAnonymousIssue;
      updateQuery(router, newLocationQuery, true, true);
    }
    if (isCrossDomainAuth()) {
      users.current.invalidate();
    }
  }, [router, query]);
  useMounting(handleMounting);

  useEffect(() => {
    if (isFromSite) {
      const newLocationQuery = { ...query };
      delete newLocationQuery.signin;
      delete newLocationQuery.fromSite;
      updateQuery(router, newLocationQuery, true, true);
      setTimeout(() => {
        dispatch(openModal('onboardModal', {}));
      }, 2000);
    }
  }, [dispatch, isFromSite, router, query]);

  const isMiniApp = location.pathname.startsWith('/loc/');

  const isReady = useMemo(() => {
    if (qrCodeLocation && qrCodeLocation.fetching) return false;
    if (selectedLocation && (selectedLocation as any).fetching) return false;
    if (suggestedLocations && (suggestedLocations as any).fetching) return false;

    return true;
  }, [qrCodeLocation, selectedLocation, suggestedLocations]);

  const codeLocation = useMemo(() => {
    if (!qrCodeLocation) {
      return null;
    }
    return qrCodeLocation.data;
  }, [qrCodeLocation]);

  const initialLocation = useMemo(() => {
    const suggestedLocation =
      (suggestedLocations as any).data && (suggestedLocations as any).data[0];
    if (!codeLocation) {
      return (selectedLocation as any).data || suggestedLocation;
    }
    return codeLocation;
  }, [codeLocation, selectedLocation, suggestedLocations]);

  const getActivityLink = useCallback(
    (activity) => {
      const activityPath = activityUrls[activity.type]({
        activity,
        location: stateLocation,
        locationIdParam: locationId,
      });

      if ((codeLocation || locationId) && activity.type !== ActivityType.open_url) {
        return `${location.pathname}${activityPath}`.replace(/\/\//g, '/');
      }

      return activityPath;
    },
    [stateLocation, locationId, codeLocation, location.pathname],
  );

  const isLocationVerifiable = useMemo(() => {
    const usersLocations = currentUser.data.owned_locations || [];

    return (
      stateLocation &&
      stateLocation.location_type &&
      stateLocation.location_type.verifiable &&
      !usersLocations.some((id) => Number(stateLocation.id) === Number(id))
    );
  }, [currentUser.data.owned_locations, stateLocation]);

  const verifyLocation = useCallback(() => {
    dispatch(openVerifyLocationModal(stateLocation));
  }, [dispatch, stateLocation]);

  const handleNotFoundLocation = useCallback(() => {
    if (isReady && !(selectedLocation as any).data) {
      navigate('/login', { replace: true });
    }
  }, [isReady, navigate, selectedLocation]);

  useEffect(() => {
    if (isMiniApp) {
      handleNotFoundLocation();
    }
  }, [handleNotFoundLocation, isMiniApp]);

  return (
    <ScrollShadowWrapper>
      <Page
        {...block()}
        title={t('activities.title')}
        isReady={isReady}
        fullWidth
        actions={
          <PageActions>{isTheOunceEmployee(currentUser.data) && <NewGroupIssueLink />}</PageActions>
        }
      >
        {!isMiniApp && <LocationCard readOnly />}

        <NewIssueSuccessModal />
        <OnboardModal />

        {isMiniApp && isReady && <LocationLabel location={initialLocation} />}

        {isReady && !isMiniApp && (
          <div {...element('location')}>
            <ActivitiesLocationSelect2
              currentUser={currentUser}
              readonly={isMiniApp}
              suggestedLocations={suggestedLocations}
              selectedLocation={location}
              initialValues={{ location: initialLocation }}
            />
            {isLocationVerifiable && <Verification onVerify={verifyLocation} />}
          </div>
        )}

        {isReady && activities.data && (
          <div {...element('items')}>
            {activities.data.map((activity) => {
              if (!VALID_ACTIVITY_TYPES.includes(activity.type)) {
                return null;
              }
              return (
                <Activity key={activity.id} activity={activity} link={getActivityLink(activity)} />
              );
            })}
          </div>
        )}
      </Page>
    </ScrollShadowWrapper>
  );
});

export default memo(ActivitiesList);
