import { pick, keyBy } from 'lodash';
import { put } from 'redux-saga/effects';

import { api } from 'lib/api';
import { createResource, updateById } from 'lib/resource';
import { closeModal } from 'lib/ui/Modal';
import { queryClient } from 'shared/lib/react-query';
import { issuesQueries } from 'entities/issues';

import { CREATE_ISSUE_MODAL, REVIEW_CHECKLIST_MODAL } from './config';

const processingActions = [
  'assign',
  'escalate',
  'return',
  'pass',
  'submit',
  'close',
  'updateAssistants',
].map((action) => `ISSUES/${action.toUpperCase()}`);

const isProcessingAction = (action) => {
  return processingActions.indexOf(action) !== -1;
};

const createListScope = (path) => {
  return {
    request({ payload }) {
      return api.get(`/issues/${path}/`, { search: payload });
    },

    selector(data, index) {
      return data && data.map((i) => index[i]);
    },

    normalizer(response) {
      return {
        data: response.map((i) => i.id),
        index: keyBy(response, (i) => i.id),
      };
    },

    reducer: (state, action) => {
      if (action.type === 'ISSUES/BY_ID') {
        return updateById(state, action.payload, {
          seen_by_me: true,
        });
      }

      if (action.type === 'ISSUES/RATE') {
        return updateById(state, action.payload.id, {
          review: {
            rating: action.payload.rating,
          },
        });
      }

      if (isProcessingAction(action.type)) {
        if (!(path === 'in_progress' && action.type === 'ISSUES/ASSIGN')) {
          return updateById(state, action.payload.id, {
            $removed: true,
          });
        }
      }

      return state;
    },
  };
};

function processIssue(action) {
  return ({ payload }) =>
    api
      .post(`/issues/${payload.id}/process/`, {
        body: {
          action,
          ...pick(payload, ['responsible', 'attributes']),
        },
      })
      .finally(() => {
        queryClient.invalidateQueries({ queryKey: issuesQueries._def });
      });
}

export default createResource('issues', {
  scopes: {
    my: createListScope('my'),
    incoming: createListScope('incoming'),
    in_progress: createListScope('in_progress'),
    on_check: createListScope('on_check'),
    closed: createListScope('resolved'),
    office: createListScope('office'),
    accidents: createListScope('accidents'),
    created_by_me: createListScope('created_by_me'),
  },

  byId: {
    request({ payload }) {
      return api.get(`/issues/${payload}/`).finally(() => {
        queryClient.invalidateQueries({ queryKey: issuesQueries._def });
      });
    },

    selector(data, index) {
      return data;
    },

    normalizer(response) {
      return {
        data: response,
      };
    },

    reducer(state, action) {
      function updateIssueData(id, data) {
        const issue = state.data[id];
        if (!issue || !issue.data) return state;

        return {
          ...state,
          data: {
            ...state.data,
            [id]: {
              ...issue,
              data: {
                ...issue.data,
                ...data,
              },
            },
          },
        };
      }

      if (action.type === 'ISSUES/RATE') {
        return updateIssueData(action.payload.id, action.payload.rating);
      }

      if (action.type === 'ISSUES/UPDATE' || action.type === 'ISSUES/UPDATE_LOCAL') {
        return updateIssueData(action.payload.id, action.payload);
      }

      return state;
    },
  },

  listsStats: {
    request() {
      return api.get('/issues/stats/');
    },

    selector(data, index) {
      return data;
    },

    normalizer(response) {
      return {
        data: response,
      };
    },
  },

  mutators: {
    create: {
      request: ({ payload }) =>
        api
          .post('/issues/create/', {
            body: {
              ...payload,
              meta: undefined,
            },
          })
          .finally(() => {
            queryClient.invalidateQueries({ queryKey: issuesQueries._def });
          }),
      *onSuccess() {
        yield put(closeModal(CREATE_ISSUE_MODAL));
      },
    },

    createGroup({ payload }) {
      let p = Promise.resolve();

      payload.locations.forEach((location) => {
        p = p.then(() => {
          return api.post('/issues/create/', {
            body: {
              ...payload,
              locations: null,
              location_id: location,
            },
          });
        });
      });

      return p.then((json) => json);
    },

    update: ({ payload }) => {
      const { id, ...body } = payload;
      return api.post(`/issues/${id}/update/`, { body });
    },

    rate({ payload }) {
      const { id, rating, comment } = payload;

      return api
        .post(`/issues/${id}/rate/`, {
          body: {
            action: 'rate',
            rating,
            comment,
          },
        })
        .finally(() => {
          queryClient.invalidateQueries({ queryKey: issuesQueries._def });
        });
    },

    make_urgent({ payload }) {
      const { id } = payload;

      return api
        .post(`/issues/${id}/make_urgent/`, {
          body: {
            action: 'make_urgent',
          },
        })
        .finally(() => {
          queryClient.invalidateQueries({ queryKey: issuesQueries._def });
        });
    },

    assign: processIssue('proceed'),
    escalate: processIssue('escalate'),
    return: processIssue('return'),
    pass: processIssue('proceed'),
    submit: processIssue('close'),

    update_assistants: ({ payload }) => {
      const { id, assistants } = payload;

      return api.post(`/issues/${id}/update_assistants/`, {
        body: { assistant_users: assistants },
      });
    },

    close: ({ payload }) => {
      const { accidentRecovery, ...data } = payload;

      return api
        .post(`/issues/${payload.id}/process/`, {
          body: {
            action: 'close',
            ...pick(data, ['responsible', 'attributes']),
          },
        })
        .then((json) => {
          if (accidentRecovery) {
            return api.post('/issues/accident/create/', {
              body: {
                issue_id: payload.id,
                ...accidentRecovery,
              },
            });
          }

          return json;
        })
        .finally(() => {
          queryClient.invalidateQueries({ queryKey: issuesQueries._def });
        });
    },

    touch: ({ payload }) => api.post(`/issues/${payload.id}/touch/`),

    updateSla: ({ payload }) => {
      const { id, ...body } = payload;
      return api.post(`/v3/issues/${id}/sla/`, { body }).finally(() => {
        queryClient.invalidateQueries({ queryKey: issuesQueries._def });
      });
    },

    removeFromList: ({ payload }) => api.post(`/issues/${payload.id}/delete/`),

    remove: {
      request: ({ payload }) => {
        const { id, comment } = payload;
        return api.delete(`/issues/${id}/`, { body: { comment } });
      },
      *onSuccess() {
        yield put(closeModal('issue'));
      },
    },

    sendChecklist({ payload }) {
      const { id, ...body } = payload;

      return api
        .post(`/issues/${id}/update/`, { body })
        .then(() => processIssue('close')({ payload: id }));
    },

    saveChecklistReview({ payload }) {
      const { id, ...body } = payload;

      return api.put(`/v2/issues/${id}/attributes/review/`, {
        body: body.attributes_review,
      });
    },

    reviewChecklist: {
      request: ({ payload }) => {
        const { id, ...body } = payload;

        return api
          .put(`/v2/issues/${id}/attributes/review/`, {
            body: body.attributes_review,
          })
          .then(() => processIssue('review')({ payload }));
      },
      *onSuccess() {
        yield put(closeModal(REVIEW_CHECKLIST_MODAL));
      },
    },

    takeToWork({ payload }) {
      const { issue_id, lat, lon } = payload;

      return api
        .post('/v2/issues/in-progress/', {
          search: { lat, lon },
          body: { issue_id },
        })
        .finally(() => {
          queryClient.invalidateQueries({ queryKey: issuesQueries._def });
        });
    },

    stopWorking({ payload }) {
      const { issue_id, lat, lon } = payload;

      return api
        .delete(`/v2/issues/in-progress/${issue_id}/`, {
          search: { lat, lon },
        })
        .finally(() => {
          queryClient.invalidateQueries({ queryKey: issuesQueries._def });
        });
    },

    reopen({ payload }) {
      return api
        .post('/v2/issues/reopen/', {
          body: payload,
        })
        .finally(() => {
          queryClient.invalidateQueries({ queryKey: issuesQueries._def });
        });
    },

    emailPDF({ payload }) {
      return api.post(`/v2/issues/${payload.id}/rendered/`, {
        body: {
          email: payload.email,
        },
      });
    },
  },
});
