import './FormBuilder.scss';
import { Field } from 'redux-form';
import { mapValues } from 'lodash';
import { Box } from '@mui/material';

import { Pagination } from 'lib/ui';
import { t } from 'lib/i18n';

import * as fieldTypes from './types';
import { FormField } from './FormField';
import validators from './validators';
import { useDebounceField } from '../../hooks/useDebounceField';
import Button from '../Button';

// Этот компонент писал сам Дьявол!
export const FieldRenderer = (props) => {
  const { meta, data, withDebounce, sx, tooltipText } = props;
  const { InputRenderer } = data;
  const { input, ...rest } = props;
  const debouncedInput = useDebounceField(
    input,
    typeof withDebounce === 'number' ? withDebounce : 350,
  );

  const fieldInput = withDebounce ? debouncedInput : props.input;
  delete fieldInput.onBlur;

  return (
    <FormField
      withoutText={props.withoutText}
      isInvalid={meta.invalid}
      isTouched={meta.touched}
      required={props.required}
      {...data}
      tooltipText={tooltipText}
      input={fieldInput}
      className={`${props.className || ''} _${data.rendererName}`}
      disabled={props.disabled}
      sx={sx}
    >
      {props.prepend}
      <InputRenderer
        {...rest}
        input={withDebounce ? debouncedInput : props.input}
        data={data.options}
      />
      {props.children}
    </FormField>
  );
};

function FieldBuilder(InputRenderer, rendererName) {
  return (props) => {
    return (
      <Field
        wait={350}
        {...props}
        normalize={props.normalize || InputRenderer.normalize}
        format={props.format || InputRenderer.format}
        validate={[
          ...(InputRenderer.validate || []),
          ...(props.validate || []),
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
          ...(props.required ? [validators.required()] : []),
        ]}
        component={FieldRenderer}
        data={{
          InputRenderer,
          rendererName,
          options: props, // TODO: Переписать эту матрёшку из пропсов в пропсах
          title: props.title,
          hint: props.hint,
          icon: props.icon,
          clearable: props.clearable,
          autoFocus: props.autoFocus,
          hintModifiers: props.hintModifiers,
          withDebounce: props.withDebounce,
          errorName: props.lastError,
          maxCount: props.data ? props.data.max_count : null,
          initialValue: props.initialValue,
          labelStyle: props.labelStyle,
          labelStyleText: props.labelStyleText,
          maxLength: props.maxLength,
          tooltip: props.tooltip,
          parentNodesDisabled: props.parentNodesDisabled,
          adornment: props.adornment,
          error: props.error,
          hideMark: props.hideMark,
          isInvalid: props.isInvalid,
          dontRemoveAll: props.dontRemoveAll,
        }}
      />
    );
  };
}

const FormBuilder = mapValues(fieldTypes, FieldBuilder) as any;

FormBuilder.validators = validators;

FormBuilder.Buttons = function FormButton(props) {
  return <div className={`FormButtons ${props.right ? '_right' : ''}`}>{props.children}</div>;
};

FormBuilder.ButtonsGroup = function FormButton({
  onCancel,
  onSubmit,
  disabled,
  submitText,
  cancelText,
  children,
  right,
  hideCancel = false,
  sx = {},
}) {
  return (
    <Box sx={sx} className={`FormButtons ${right ? '_right' : ''}`}>
      {!hideCancel && (
        <Button ghost onClick={onCancel}>
          {cancelText || t('common.cancel')}
        </Button>
      )}

      <Button onClick={onSubmit} disabled={disabled}>
        {submitText}
      </Button>

      {children}
    </Box>
  );
};

const handlePageChange = (onChange) => (offset) => {
  onChange(offset);
};

const PaginationRenderer = ({ input, data }) => {
  return (
    <Pagination
      {...input}
      total={data.total}
      perPage={data.perPage}
      offset={Number.isNaN(input.value) ? 0 : input.value}
      routerOffset={data.routerOffset}
      onPageChange={handlePageChange(input.onChange)}
      sx={data.sx}
    />
  );
};

FormBuilder.Pagination = ({ total, perPage, normalize, format, routerOffset, sx }) => {
  return (
    <Field
      name="offset"
      component={PaginationRenderer}
      data={{
        total,
        perPage,
        routerOffset,
        sx,
      }}
      normalize={normalize}
      format={format}
    />
  );
};

const emptyRenderer = () => null;

FormBuilder.Hidden = (props) => {
  return <Field {...props} component={emptyRenderer} />;
};

export default FormBuilder;
