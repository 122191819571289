import { Box } from '@mui/material';

import { t } from 'lib/i18n';
import { ModalV2 } from 'shared/ui/modal-v2';
// eslint-disable-next-line import/no-cycle
import { Button } from 'lib/ui';
import { Icon } from 'lib/ui/icon';

import './WarningModal.scss';

const { block, element } = bem('WarningModal');

interface Props {
  title: string;
  subtitle: string;
  body?: React.ReactNode;
  onClose: () => void;
}

export const WarningModal = ({ title, subtitle, body, onClose }: Props) => {
  return (
    <ModalV2 title="" small open onClose={onClose}>
      <Box {...block()}>
        <Icon {...element('icon')} glyph="warning" />
        {title && <p {...element('title')}>{title}</p>}
        {subtitle && <p {...element('subtitle')}>{subtitle}</p>}
        {body}
        <Button {...element('button')} onClick={onClose}>
          {t('common.ok')}
        </Button>
      </Box>
    </ModalV2>
  );
};
