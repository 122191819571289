import { t_prefixed } from 'lib/i18n';

import './RoleRelationsList.scss';
import { List } from './List';

const { block, element } = bem('RoleRelationsList');

const label = t_prefixed('user_roles.modals.remove');

export const RoleRelationsList = (data) => {
  const { issue_types, issue_types_attributes, managed_objects, managed_object_type_attributes } =
    data.data;

  const locations =
    managed_objects.length === 0 ? null : managed_objects.map((location) => location.name);
  const issueTypes = issue_types.length === 0 ? null : issue_types.map((type) => type.name);
  const issueTypesAttributes =
    issue_types_attributes.length === 0
      ? null
      : issue_types_attributes.map((attr) => `${attr.name} → ${attr.attr_name}`);
  const locationTypesAttributes =
    managed_object_type_attributes.length === 0
      ? null
      : managed_object_type_attributes.map((attr) => `${attr.name} → ${attr.attr_name}`);

  if (
    issue_types.length === 0 &&
    issue_types_attributes.length === 0 &&
    managed_objects.length === 0 &&
    managed_object_type_attributes.length === 0
  ) {
    return null;
  }

  return (
    <div {...block()}>
      <div {...element('title')}>{label('description')}</div>
      {locations && (
        <List title={label('related_locations')} content={locations} {...element('list')} />
      )}
      {locationTypesAttributes && (
        <List
          title={label('related_location_attributes')}
          content={locationTypesAttributes}
          {...element('list')}
        />
      )}
      {issueTypes && (
        <List title={label('related_issue_types')} content={issueTypes} {...element('list')} />
      )}
      {issueTypesAttributes && (
        <List
          title={label('related_issue_attributes')}
          content={issueTypesAttributes}
          {...element('list')}
        />
      )}
    </div>
  );
};
