import { useCallback, ChangeEvent, useEffect, useState, useMemo } from 'react';
import { keyBy, isNil } from 'lodash';
import {
  RadioGroup,
  IconButton,
  Box,
  Radio,
  FormControlLabel,
  TextField,
  Chip,
} from '@mui/material';
import { ClearOutlined as ClearIcon } from '@mui/icons-material';
import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import DateRangePicker from 'lib/ui/Datepicker/DateRangePicker';
import {
  CurrencyInput,
  Datepicker,
  DateTimePicker,
  Checkbox as CheckoxCmp,
  TreeViewSelect as OriginalTreeViewSelect,
  Rating as OriginalRating,
  SimpleSelect as OriginalSimpleSelect,
  MonetarySign,
} from 'lib/ui';
import ListInput from 'lib/ui/ListInput';
import { TimePicker } from 'shared/ui/time-picker';
import { CalendarPicker } from 'shared/ui/calendar-picker';

import {
  convertDateToLocal,
  normalizeDateInUTC,
  normalizeEndDate,
  normalizeStartDate,
} from '../../../helpers/dates';

export { Select } from './Select/Select';
export { MultiSelect } from './Select/MultiSelect';
export { YesNo } from './YesNo';
export { TelOld } from './Tel';
export { default as Tel } from './Tel';
export { default as Number } from './Number';
export { default as ConfirmCode } from './ConfirmCode';
export { JsonEditor } from './JsonEditor/JsonEditorLazy';
export { default as Textarea } from './TextArea';
export { default as BookingTimeSlots } from './Slots/TimeSlots/BookingTimeSlots';
export { default as BookingDaySlots } from './Slots/DaySlots/BookingDaySlots';
export { default as SharingDaySlots } from './Slots/DaySlots/SharingDaySlots';
export { default as DaysCarouselFilter } from './DaysCarouselFilter';
export { default as Geolocation } from './Geolocation';
export { default as TextSamples } from './TextSamples';
export { default as QRcodeVerify } from './QRcodeVerify';
export { default as UserAvatarEditor } from './UserAvatarEditor';
export { default as MoreLessEqual } from './MoreLessEqual';
export { default as FlatList } from './FlatList';
export { default as CurrencySelect } from './CurrencySelect';
export { default as SearchWithButton } from './SearchWithButton';

export const Text = ({ input, data }): JSX.Element => {
  const {
    maxLength,
    placeholder,
    onKeyPress,
    autoComplete,
    autoFocus,
    className,
    inputClassName,
    clearable,
    type = 'text',
    inputRef,
  } = data;
  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    setCurrentValue(input.value);
  }, [input.value]);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    input.onChange(evt);
    setCurrentValue(evt.target.value);
  };

  const handleClean = () => {
    input.onChange('');
    setCurrentValue('');
  };

  return (
    <TextField
      {...input}
      type={type}
      value={currentValue}
      onChange={handleChange}
      onBlur={handleChange}
      variant="outlined"
      className={`input_text${className ? ` ${className}` : ''}`}
      placeholder={placeholder}
      onKeyPress={onKeyPress}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      inputRef={inputRef}
      inputProps={{ maxLength, className: inputClassName }}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={handleClean}
            sx={{
              p: 0,
              position: 'absolute',
              right: '0.5rem',
              visibility: currentValue && clearable ? 'visible' : 'hidden',
            }}
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          paddingRight: 0,
        },
      }}
    />
  );
};

export const MultiText = ({ input, data }) => {
  const [currentValue, setCurrentValue] = useState('');

  const isNormalQuantity = !(data.maxLength && data.maxLength <= input.value.length);

  const changeValues = (newValue: string) => {
    input.onChange([...input.value, newValue]);
    setCurrentValue('');
  };

  const handleKeyUp = (evt) => {
    if (
      isNormalQuantity &&
      (evt.key === ' ' || evt.key === ';' || evt.key === 'Enter' || evt.key === ',')
    ) {
      if ((evt.target as HTMLInputElement).value) {
        changeValues((evt.target as HTMLInputElement).value);
      }
      evt.preventDefault();
    }
  };

  const handleBlur = (evt) => {
    if (isNormalQuantity && (evt.target as HTMLInputElement).value) {
      changeValues((evt.target as HTMLInputElement).value);
    }
  };

  const handleChange = (evt: ChangeEvent) => {
    if (isNormalQuantity) {
      setCurrentValue((evt.target as HTMLInputElement).value);
    }
  };

  const handleDelete = (item, index) => {
    const arr = [...input.value];
    arr.splice(index, 1);
    input.onChange(arr);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        border: ({ palette }) => `1px solid ${palette?.separators?.main}`,
        '& .MuiTextField-root': {
          flexGrow: 1,
          width: '0!important',
          minWidth: '30px',
        },
        '& .MuiOutlinedInput-root': {
          padding: 0,
          '& .MuiOutlinedInput-input': {
            border: 'none',
          },
        },
      }}
    >
      {(input.value || []).map((item, index) => (
        // TODO: Add key prop
        // eslint-disable-next-line react/jsx-key
        <Chip
          size="small"
          onDelete={() => handleDelete(item, index)}
          label={item}
          sx={{ margin: '5px' }}
        />
      ))}
      <TextField
        value={currentValue}
        onChange={handleChange}
        onKeyDown={handleKeyUp}
        onBlur={handleBlur}
      />
    </Box>
  );
};

export const List = ({ input }) => {
  return <ListInput {...input} />;
};

export const Email = ({ input }): JSX.Element => {
  return (
    <TextField
      {...input}
      fullWidth
      type="email"
      autoComplete="email"
      variant="outlined"
      InputProps={{
        endAdornment: (
          <input
            type="text"
            name="block-autocomplete"
            autoComplete="text"
            style={{
              opacity: 0.0,
              width: '1px',
              height: '1px',
              position: 'absolute',
            }}
            tabIndex={-1}
          />
        ),
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          paddingRight: '0!important',
        },
      }}
    />
  );
};

export const Password = ({ input, data }): JSX.Element => {
  return (
    <TextField
      {...input}
      fullWidth
      type="password"
      autoComplete={data.autoComplete}
      placeholder={data.placeholder}
    />
  );
};

export const Date = ({ input, data, meta }) => {
  return (
    <Datepicker
      {...input}
      id={input.name}
      placeholder={data.placeholder}
      minDate={data.minDate}
      maxDate={data.maxDate}
      form={meta.form}
    />
  );
};

Date.normalize = normalizeDateInUTC;

Date.format = convertDateToLocal;

export const DateRange = ({ input, data }) => {
  return (
    <DateRangePicker
      {...input}
      id={input.name}
      placeholder={data.placeholder}
      isDayBlocked={data.isDayBlocked}
    />
  );
};

DateRange.normalize = (value) => {
  if (value) {
    return {
      start: normalizeStartDate(value.start),
      end: normalizeEndDate(value.end),
    };
  }
  return { start: null, end: null };
};

DateRange.format = (value) => {
  if (value) {
    return {
      start: convertDateToLocal(value.start),
      end: convertDateToLocal(value.end),
    };
  }
  return { start: null, end: null };
};

export const DateTime = ({ input, data, meta }) => {
  let minTime;

  const roundNumber = (n) => {
    return 5.0 * Math.ceil(n / 5.0);
  };

  if ((data.data?.options?.from_now || data.fromNow) && !data.data?.default_value) {
    const mins = moment().minutes();
    const closestMinutes = roundNumber(mins);
    const minutesToAdd = closestMinutes - mins;
    minTime = moment().startOf('minute').add(minutesToAdd, 'minutes');
  }

  return (
    <DateTimePicker
      {...input}
      id={input.name}
      placeholder={data.placeholder}
      minTime={minTime}
      limitTime={data.limitTime}
      form={meta.form}
    />
  );
};

export const Time = ({
  input,
  label,
  data,
  placeholder,
  minTime,
  maxTime,
  width,
  format = 'HH:mm',
}) => {
  const handleChange = (value: string | null) => {
    input.onChange(value);
  };

  const isError = useMemo(() => {
    if (!minTime && !maxTime) {
      return false;
    }

    const timeValue = moment(input.value, format);
    const minTimeMoment = moment(minTime, format);
    const maxTimeMoment = moment(maxTime, format);

    if (timeValue?.isBefore(minTimeMoment) || timeValue?.isAfter(maxTimeMoment)) {
      return true;
    }

    return false;
  }, [format, input.value, maxTime, minTime]);

  return (
    <TimePicker
      {...data}
      {...input}
      label={label}
      onChange={handleChange}
      placeholder={placeholder}
      minTime={minTime}
      maxTime={maxTime}
      width={width}
      error={isError}
    />
  );
};

export const TimeInterval = ({ input, optionValue }) => {
  const handleChange = (name: string) => (value: string | null) => {
    if (!value && !optionValue) {
      input.onChange({});
      return;
    }

    if (value) {
      input.onChange({ ...optionValue, [name]: value });
      return;
    }

    const { [name]: _, ...obj } = optionValue;
    input.onChange(obj);
  };

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      <TimePicker
        value={optionValue?.min_time}
        placeholder={t('time_picker.interval.from')}
        onChange={handleChange('min_time')}
        maxTime={optionValue?.max_time}
      />
      <TimePicker
        value={optionValue?.max_time}
        placeholder={t('time_picker.interval.to')}
        onChange={handleChange('max_time')}
        minTime={optionValue?.min_time}
      />
    </div>
  );
};

export const Calendar = ({ input, data, label, fromNow, dateFormat }) => {
  const handleChange = (value: string[]) => {
    input?.onChange(value[0] ?? '');
  };

  return (
    <CalendarPicker
      {...data}
      {...input}
      label={label}
      fromNow={fromNow}
      onChange={handleChange}
      value={input.value ? [input.value] : []}
      format={dateFormat}
    />
  );
};

DateTime.normalize = normalizeDateInUTC;

DateTime.format = convertDateToLocal;

export const Currency = ({ input, data }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CurrencyInput {...data} {...input} />
      {data.data && <MonetarySign currency={data.data.options.currency} />}
    </Box>
  );
};

export const Checkbox = ({ input, label }) => {
  return <CheckoxCmp {...input} checked={!!input.value} label={label} />;
};

export const CheckboxGroup = ({ input, options, disabled = false }) => {
  const checked = keyBy(input.value || [], (i) => i);

  const handleChange = useCallback(
    (option) => (value) => {
      input.onChange(
        value
          ? [...(input.value || []), option.value]
          : (input.value || []).filter((i) => i !== option.value),
      );
    },
    [input],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {options &&
        options.map((option) => (
          <CheckoxCmp
            {...input}
            key={option.value}
            label={option.label}
            checked={!isNil(checked[option.value])}
            value={!isNil(checked[option.value])}
            onChange={handleChange(option)}
            disabled={disabled}
          />
        ))}
    </Box>
  );
};

export const RadioButtons = ({
  input: { onChange: inputOnChange, value, name },
  options,
  onChange,
  defaultValue,
}) => {
  useEffect(() => {
    if (defaultValue !== null && value === undefined) {
      inputOnChange(defaultValue);
    }
  }, [defaultValue, inputOnChange, value]);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    inputOnChange(evt.target.value);
    onChange?.(evt.target.value);
  };

  return (
    <RadioGroup row value={value} onChange={handleChange} name={name}>
      {options.map((option) => (
        <FormControlLabel
          value={option.value}
          control={<Radio sx={{ color: ({ palette }) => palette.primary.main }} />}
          label={option.label}
          key={option.label}
        />
      ))}
    </RadioGroup>
  );
};

export const Rating = ({ input, data }) => {
  return (
    <OriginalRating {...input} starsCount={data.starsCount} withLabel={data.withLabel} editable />
  );
};

export const TreeViewSelect = ({ input, data }) => {
  return (
    <OriginalTreeViewSelect
      clearable={data.clearable}
      tree={data.tree}
      multiple={data.multiple}
      expandedLevel={data.expandedLevel}
      getLabel={data.getLabel}
      renderFilter={data.renderFilter}
      nodesFilter={data.nodesFilter}
      autoSelectSingleOption={data.autoSelectSingleOption}
      getItemFullPath={data.getItemFullPath}
      searchable={data.searchable}
      onSearch={data.onSearch}
      filterable={data.filterable}
      selectAll={data.selectAll}
      placeholder={data.placeholder}
      withLocationCards={data.withLocationCards}
      parentNodesDisabled={data.parentNodesDisabled}
      isDisplayFolders={data.isDisplayFolders}
      startIcon={data.startIcon}
      dontRemoveAll={data.dontRemoveAll}
      {...input}
    />
  );
};

export const SimpleSelect = ({ input, data }) => {
  return <OriginalSimpleSelect {...input} {...data} />;
};

export const Custom = ({ input, data }) => {
  const { component, ...dataProps } = data;
  const Component = component;

  return <Component {...dataProps} {...input} />;
};
