import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { memoize, mapValues, isEqual } from 'lodash';

import { fakeNull } from 'config/constants';

const isEmptyValue = (value) =>
  value === fakeNull ||
  value === undefined ||
  value === null ||
  value === '' ||
  (typeof value === 'string' && value.trim() === '');

export const createValidator = (fn, name) =>
  memoize((args) => (value) => (fn(args)(value) ? undefined : name));

// VALIDATORS
const required = () => (value) => !isEmptyValue(value);

const checked = () => (value) => value === true;

const notEmpty =
  () =>
  (value = []) =>
    value.length > 0;

const maxLength = (length) => (value) => !value || value.length <= length;

const minLength = (length) => (value) => !value || value.length >= length;

const length = (x) => (value) => !value || String(value).length === x;

const MAX_NUMBER = 2 ** 31 - 1;

const min =
  (minValue = -MAX_NUMBER) =>
  (value) =>
    isEmptyValue(value) || Number(value) >= minValue;

const max =
  (maxValue = MAX_NUMBER) =>
  (value) =>
    isEmptyValue(value) || Number(value) <= maxValue;

const mobilePhone = () => (value) => !value || value.replace(/[^+\d]/g, '').match(/(\+7|8)\d{10}/);

const internationalMobilePhone = () => (value) => (value ? isPossiblePhoneNumber(value) : true);

const notInList =
  (list = []) =>
  (value) =>
    list.every((item) => !isEqual(item, value));

const isAllItemsInList =
  (list: any = []) =>
  (value) =>
    (value || []).every((item) => list.indexOf(item) > -1);

const pattern =
  (x) =>
  (value = '') => {
    if (Array.isArray(value)) {
      if (value.length === 0) return false;
      return value.every((item) => item.match(x));
    }
    return !value || value.match(x);
  };

const password = () => pattern(new RegExp('^[!"#$%&\'()*+,-./0-9:;<=>?@`A-Za-z\\[\\]~^_{}|]+$'));

const coordinates = () =>
  pattern(
    new RegExp(
      '^((-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,6})?))),(\\s)?((-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,6})?)))$',
    ),
  );

const email = () => pattern(new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,10}$'));

export const regexValidator = createValidator(
  (regex) => (value) => {
    if (!value || value.match(new RegExp(regex))) return true;
    return false;
  },
  'regexValidator',
);

export default mapValues(
  {
    required,
    notEmpty,
    maxLength,
    minLength,
    length,
    min,
    max,
    mobilePhone,
    internationalMobilePhone,
    pattern,
    password,
    notInList,
    isAllItemsInList,
    checked,
    coordinates,
    email,
  },
  createValidator,
);
