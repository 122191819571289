import LocationFullPath from 'app/locations/LocationFullPath';
import { bem } from 'lib/bem';

import './IssueLocation.scss';

const { block } = bem('IssueLocation');

// eslint-disable-next-line react/function-component-definition
export default function IssueLocation({ issue, className, short = false, ...props }) {
  // добавлено из-за новой стуктуры списков, чтобы ничего не сломать
  // TODO: переделать при реновации
  if (issue.location_parent_names && issue.source !== 'chat') {
    const location = {
      parent_names: issue.location_parent_names,
      name: issue.location_name,
    };
    return (
      <div {...block({}, className)} {...props}>
        <LocationFullPath location={location} />
      </div>
    );
  }

  if (!issue.location_id) {
    return null;
  }

  if (short) {
    return (
      <div {...block({}, className)} {...props}>
        {issue.location.name}
      </div>
    );
  }

  return (
    <div {...block({}, className)} {...props}>
      <LocationFullPath location={issue.location} />
    </div>
  );
}
