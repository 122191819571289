import { useEffect, useState } from 'react';
import moment from 'moment';
import qrcode from 'qrcode-js';

import { bem } from 'lib/bem';
import { Logo } from 'lib/ui';
import { api } from 'lib/api';
import brandedData from 'config/brandedData';

import './TeoremaBookingScheduler.scss';

qrcode.errorCorrectLevel = 'L';

interface Prosp {
  path: string;
}

type TSlot = {
  title: string;
  status: 'available' | 'booked' | 'unavailable';
};

interface ISlotsData {
  version: string;
  data: TSlot[];
}

const { block, element } = bem('BookingScheduler');

const NoSlots = () => {
  return (
    <div {...element('noslots-wrapper')}>
      <p {...element('noslots-text')}>{t('bookings.pages.booking_scheduler.no_slots')}</p>
    </div>
  );
};

const Schedule = ({ data }: { data: TSlot[] }) => {
  return (
    <div {...element('slots-wrapper')}>
      {data.map((item) => {
        return (
          <div key={item.title} {...element('slots-item', { [item.status]: true })}>
            {item.title}
          </div>
        );
      })}
    </div>
  );
};

const TeoremaBookingScheduler = ({ path }: Prosp) => {
  const [data, setData] = useState<ISlotsData | null>(null);
  const [version, setVersion] = useState<string | null>(null);
  const [currentDate, setCurrentDate] = useState(moment().format('dd, D MMM  kk:mm'));
  const code = path.slice(path.lastIndexOf('/') + 1);

  useEffect(() => {
    api
      .get(`/booking_schedule/${code}/`)
      .then((resp) => {
        if (resp.httpStatusCode >= 200 && resp.httpStatusCode < 300) {
          setData(resp.response);
          setVersion(resp.response.version);
        }
      })
      .catch((err) => {});
  }, [code]);

  useEffect(() => {
    setInterval(() => {
      api
        .get(`/booking_schedule/${code}/`)
        .then((resp) => {
          if (resp.httpStatusCode >= 200 && resp.httpStatusCode < 300) {
            setData(resp.response);
          }
        })
        .catch((err) => {});
      setCurrentDate(moment().format('dd, D MMM  kk:mm'));
    }, 30000);
  }, [code]);

  useEffect(() => {
    if (version && data?.version && data.version !== version) {
      window.location.reload();
    }
  }, [data, version]);

  return (
    <div {...block()}>
      <div {...element('header')}>
        <h2 {...element('title')}>{t('bookings.pages.booking_scheduler.title')}</h2>
        <p {...element('date')}>{currentDate}</p>
      </div>
      <div>{data?.data.length ? <Schedule data={data.data} /> : <NoSlots />}</div>
      <div {...element('footer')}>
        <div {...element('footer-info')}>
          <div {...element('footer-status')}>
            <p {...element('footer-status_item', { green: true })}>
              {t('bookings.pages.booking_scheduler.available')}
            </p>
            <p {...element('footer-status_item')}>
              {t('bookings.pages.booking_scheduler.unavailable')}
            </p>
          </div>
          <div {...element('footer-divider')} />
          <Logo inverted />
        </div>
        <div {...element('footer-qrcode-wrapper')}>
          <div {...element('footer-qrCode')}>
            <img
              style={{ width: '100%' }}
              alt="qrCode"
              src={qrcode.toDataURL(
                `https://${
                  window.location.host === 'localhost:9000'
                    ? 'waveservice-stage.wavea.cc'
                    : window.location.host
                }/book?code=${code}`,
              )}
            />
          </div>
          {brandedData.clientApp === 'smart.space' && (
            <p {...element('footer-qrcode-cta')}>{t('bookings.pages.booking_scheduler.cta')}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeoremaBookingScheduler;
