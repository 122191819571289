import QRCode from 'react-qr-code';
import { Typography } from '@mui/material';

// eslint-disable-next-line import/no-cycle
import { useAuthContext } from 'app/providers';
import { Button } from 'lib/ui';
import { t_prefixed } from 'lib/i18n';

import './TgPopoverContent.scss';

const label = t_prefixed('profile_form.tg_popover');
const { block, element } = bem('TgPopoverContent');

export const TgPopoverContent = () => {
  const { userData } = useAuthContext();
  const link = userData?.telegram_subscription_link || '';
  return (
    <div className={block().className}>
      <div className={element('qrCode').className}>
        <QRCode value={link} size={112} level="L" />
      </div>
      <div className={element('textBlock').className}>
        <Typography className={element('text').className}>{label('text')}</Typography>
        <Button href={link} target="_blank" className={element('button').className}>
          {label('button')}
        </Button>
      </div>
    </div>
  );
};
