import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { bem } from 'lib/bem';

import './CircularProgress.scss';

const { block, element } = bem('CircularProgressWithLabel');

export const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  const color =
    Math.round(props.value) > 79 ? 'done' : Math.round(props.value) > 49 ? 'progress' : 'start';

  return (
    <div className={block().className}>
      <div className={element('wrapper').className}>
        <CircularProgress
          className={element(`${color}`).className}
          size={48}
          variant="determinate"
          {...props}
        />
        <div className={element('label').className}>
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </div>
      </div>
    </div>
  );
};
