const boldRegex = /\\u000B\\u000A/;
const pseudoRegex = '\u000B\u000A';

/**
 * Set returned values in dangerouslySetInnerHTML
 * @param title
 */
export const useParseTitle = (title: string) => {
  if (!title) {
    return ['', ''];
  }

  const titleArray =
    (boldRegex.test(title) && title.split(boldRegex)) ||
    (title.includes(pseudoRegex) && title.split(pseudoRegex));

  const boldText = titleArray ? titleArray[0] : '';
  const commonText = titleArray ? titleArray.slice(1).join(' ') : title;

  return [boldText, commonText];
};
