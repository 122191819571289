import { FormBuilder } from 'lib/ui/index';
import { t_prefixed } from 'lib/i18n';

import userRolesResource from './userRoles.resource';
import { IUserRoles } from './lib';

export const modalName = 'dictEditorModal';

export const label = t_prefixed('user_roles.pages.list');

export const modalProps = {
  connectResource: (state, options) => (userRolesResource as any).list(state, options),
  onAddItem: (userRole) => userRolesResource.create(userRole),
  onUpdateItem: (userRole) => userRolesResource.update(userRole),
  renderFormFields: () => {
    return <FormBuilder.Text required name="name" title={t('dictEditor.name')} />;
  },
  getFormValues: (item) => {
    return {
      name: item.name,
    };
  },
  editModalTitle: label('edit_user_role_title'),
  newModalTitle: label('add_user_role_title'),
  editable: true,
};

export const getFilteredItems = (roles, filters): IUserRoles[] => {
  const { data } = roles;

  if (!filters || !filters.search) return data || [];

  return (
    data &&
    data.filter((item) => item.name.toLowerCase().includes(filters.search.trim().toLowerCase()))
  );
};
