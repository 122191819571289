import { Helmet } from 'react-helmet';

import MetaTags from './MetaTags';
import AppFavicons from './AppFavicons';
import { BrandName } from '../../types/brand';
import { NODE_ENV } from '../../../tools/webpack/config';

interface Props {
  brand: BrandName;
}

const AppHead = ({ brand }: Props) => {
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
        <link rel="manifest" href={`/brands/${brand}/manifest.json`} />
        {/* {brand === 'waveservice' && NODE_ENV !== 'development' && (
          <script type="text/javascript">
            {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();
            for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            ym(99536310, "init", {
                  clickmap:true,
                  trackLinks:true,
                  accurateTrackBounce:true,
                  webvisor:true
            });
          `}
          </script>
        )} */}
        {/* {brand === 'waveservice' && NODE_ENV !== 'development' && (
          <noscript>
            {`<div>
            <img
              src="https://mc.yandex.ru/watch/99536310"
              style={{ position: 'absolute', left: '-9999px' }}
              alt=""
            />
          </div>`}
          </noscript>
        )} */}
      </Helmet>
      <MetaTags brand={brand} />
      <AppFavicons brand={brand} />
    </>
  );
};

export default AppHead;
