import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { bem } from 'lib/bem';
import { Logo } from 'lib/ui';
import { Icon } from 'lib/ui/icon';
import { brandName } from 'config/constants';

import './SideBanner.scss';

const { block, element } = bem('SideBanner');

const header = t([`sidebanner.header.${brandName}`, 'sidebanner.header.default'], brandName);

interface Props {
  footer: ReactNode;
}

const SideBanner = ({ footer }: Props) => {
  const { t } = useTranslation();

  const functionalItems: { text: string[] }[] = t('sidebanner.functional.lines');

  return (
    <div {...block()}>
      <div {...element('head')}>
        <Logo inverted />
        <div {...element('content')}>
          <div {...element('title')}>
            <Typography {...element('caption')} component="div">
              {t('sidebanner.caption')}
            </Typography>
            <Typography {...element('header')} component="div">
              {header}
            </Typography>
          </div>
          <div {...element('icon')}>
            <Icon glyph="functional-icons" />
          </div>
          <div>
            {functionalItems.map((line, index) => (
              <div key={index}>
                {line.text.map((item) => (
                  <Typography {...element('functional_item')} component="span" key={item}>
                    {item}
                  </Typography>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div {...element('sidebannerFooter')}>{footer}</div>
    </div>
  );
};

export default SideBanner;
