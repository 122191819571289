export const List = (props) => {
  const { title, content, className } = props;
  return (
    <div>
      <p>{title}</p>
      <ul className={className}>
        {content.map((elem, index) => (
          <li key={index}>{elem}</li>
        ))}
      </ul>
    </div>
  );
};
