import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIsFetching } from '@tanstack/react-query';

import { getClasses } from 'lib/react-utils';

import './page-loader.scss';

interface PageLoaderProps {
  loading: boolean;
}

const PageLoader: React.FC<PageLoaderProps> = ({ loading }) => {
  const [loadingState, setLoadingState] = useState<'progress' | 'finished' | null>(null);
  const isFetching = useIsFetching();

  useEffect(() => {
    if (loading || !!isFetching) {
      setLoadingState('progress');
    } else {
      setLoadingState('finished');
    }
  }, [loading, isFetching]);

  return (
    <div className="page-loader">
      <div
        className={getClasses({
          'page-loader__fill': true,
          _progress: loadingState === 'progress',
          _finished: loadingState === 'finished',
        })}
      />
    </div>
  );
};

export default connect((state: any) => ({
  loading: state.loading > 0,
}))(PageLoader);
