import { createQueryKeys } from '@lukemorales/query-key-factory';

import { apiClient_v4 } from 'shared/api/v4/client';
import { GetIssueListIssuesIssueListTypeGetParams } from 'shared/api/v4/swagger/data-contracts';

export const issuesQueries = createQueryKeys('issue', {
  list: ({ issueListType, ...params }: GetIssueListIssuesIssueListTypeGetParams) => ({
    queryKey: [issueListType, params],
    queryFn: () => apiClient_v4.issues.getIssuesList({ issueListType, ...params }),
  }),
  unseen: {
    queryKey: null,
    queryFn: () => apiClient_v4.issues.getUnseenCounters(),
  },
});
