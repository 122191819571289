import { Paper } from '@mui/material';

import { IRoundReportItem } from 'app/roundsReports/types';
import { bem } from 'lib/bem';
import { t_prefixed } from 'lib/i18n';
import { Date, Table } from 'lib/ui';
import { IssueStatus } from 'app/issues/IssueFields';
import { CircularProgressWithLabel } from 'shared/ui/circular-progress';

interface Props {
  items: Array<IRoundReportItem>;
  onRowClick: (row: any) => void;
}

const { block, element } = bem('RoundReportTable');
const label = t_prefixed('round_reports.columns');

const columns = [
  {
    header: label('round_id'),
    accessor: 'display_id',
    render: (props) => <div>{props.value}</div>,
  },
  {
    header: label('name'),
    accessor: 'name',
    render: (props) => <div>{props.value}</div>,
  },
  {
    header: label('location'),
    accessor: 'managed_object',
    render: (props) => {
      const value = props.value.split(' — ');
      return (
        <div className="NestedPath">
          {value &&
            value.map((item, index) => (
              <div className="NestedPath__item" key={index}>
                {item}
              </div>
            ))}
        </div>
      );
    },
  },
  {
    header: label('create_date'),
    accessor: 'created',
    render: (props) => <Date value={props.value} showIcon={false} showTime={false} />,
  },
  {
    header: label('status'),
    accessor: 'current_status',
    render: (props) => (
      <IssueStatus
        value={props.value}
        showIcon={false}
        showInProgress
        overdue={props.row.overdue}
      />
    ),
  },
  {
    header: label('close_date'),
    accessor: 'close_date',
    render: (props) => <Date value={props.value} showIcon={false} showTime={false} />,
  },
  {
    header: label('author'),
    accessor: 'author',
    render: (props) => <div>{props.value}</div>,
  },
  {
    header: label('responsible'),
    accessor: 'responsible',
    render: (props) => <div>{props.value}</div>,
  },
  {
    header: label('progress'),
    accessor: 'progress',
    render: (props) => <CircularProgressWithLabel value={props.value} />,
  },
  {
    header: label('related_issues'),
    accessor: 'related_issues_count',
    render: (props) => <div>{props.value}</div>,
  },
];

const RoundReportTable = ({ items, onRowClick }: Props) => {
  return (
    <Paper
      sx={{
        borderRadius: '8px',
        padding: '0 8px',
        border: '1px solid rgba(231, 238, 255, 0.72)',
        boxShadow: '0px 1px 12px -2px rgba(231, 238, 255, 0.64)',
        margin: '1rem 0',
        width: '100%',
      }}
    >
      <Table
        data={items}
        {...element('table')}
        columns={columns}
        fixedHeaderAtTableTop
        onRowClick={onRowClick}
        sx={{
          maxWidth: 300,
          border: 'none !important',
          '& .MuiTableCell-head': {
            borderRight: 'none',
            borderBottom: '1px solid rgba(231, 238, 255, 0.72) !important',
            color: '#667790',
            padding: '14px 24px',
          },
          '& .MuiTableCell-body': {
            color: '#112950',
            padding: '14px 24px',
            fontSize: '14px',
            lineHeight: '24px',
          },
          '& .MuiTableRow-root': {
            '&:last-child td, &:last-child th': { border: 0 },
            '&:hover': {
              backgroundColor: '#fff !important',
              cursor: 'pointer',
            },
          },
        }}
      />
    </Paper>
  );
};

export default RoundReportTable;
