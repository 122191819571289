// eslint-disable-next-line import/no-cycle
import { Date } from 'lib/ui';
import { t } from 'lib/i18n';

import './issue-fields.scss';

export const IssueId = ({ issue, short = false }) => {
  return (
    <div className="IssueId">
      {/* TODO: #i18n Add translation key type check */}
      {short && t(`issues.${issue.metatype}`, issue.metatype)} {issue.display_id}
    </div>
  );
};

export { default as IssueStatus } from './IssueStatus/IssueStatus';
export { default as IssueLocation } from './IssueLocation/IssueLocation';
export { default as IssueRating } from './IssueRating/IssueRating';
export { default as IssueDate } from './IssueDate/IssueDate';
export { default as IssueCloseDate } from './IssueCloseDate';
export { default as ExpiredIssue } from './ExpiredIssue';
export { default as IssueRemainingTime } from './IssueRemainingTime';
export { default as IssueTypeName } from './IssueTypeName';
export { default as IssueInProgress } from './IssueInProgress';
export { default as IssueResponsible } from './IssueResponsible';
export { default as IssueComment } from './IssueComment';
export { IssueUrgency } from './IssueUrgency/IssueUrgency';

export const IssueExpectedCloseDate = ({ issue, format = 'long' }: any = {}) => {
  return (
    <div className="IssueDate">
      <Date value={issue.close_date_expected} format={format} />
    </div>
  );
};

export const IssueAuthor = ({ issue }) => {
  return <p>{issue.author?.full_name ?? issue.author_full_name}</p>;
};
