/* eslint-disable react/display-name */
import {
  ChangeEventHandler,
  ComponentType,
  DragEventHandler,
  FC,
  FocusEventHandler,
  ReactNode,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { mapValues, pick } from 'lodash';

// eslint-disable-next-line import/no-cycle
import FormBuilder, { FieldRenderer } from 'lib/ui/FormBuilder/FormBuilder';
import * as fieldTypes from 'lib/ui/FormBuilder/types';

type InputComponentProps = {
  input: {
    name: string;
    onBlur: FocusEventHandler;
    onChange: ChangeEventHandler;
    onDragStart?: DragEventHandler;
    onDrop?: DragEventHandler;
    onFocus?: FocusEventHandler;
    value: unknown;
  };
  meta: {
    active?: boolean;
    autofilled?: boolean;
    asyncValidationg?: boolean;
    dirty?: boolean;
    error?: string;
    form?: string;
    initial?: unknown;
    invalid?: boolean;
    pristine?: boolean;
    submitting?: boolean;
    submitFailed?: boolean;
    touched?: boolean;
    valid?: boolean;
    visited?: boolean;
    warning?: boolean;
  };
  data: Record<string, unknown>;
  [props: string]: unknown;
};

type ReactHookFormInputProps = {
  name: string;
  disabled?: boolean;
  className?: string;
  prepend?: ReactNode;
  children?: ReactNode;
  sx?: unknown;
  [prop: string]: unknown;
};

const ReactHookFormInputWrapper =
  (WrappedInput: ComponentType<InputComponentProps>) =>
  ({ name, disabled, className, children, prepend, sx, ...rest }: ReactHookFormInputProps) => {
    // Пропсы, которые используются компонентами инпутов напрямую, минуя `input`, `data` и `meta`
    const extraProps = pick(rest, ['label', 'onChange', 'defaultValue']);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        render={({
          field: { name: fieldName, onBlur, onChange, value, disabled: fieldDisabled },
          fieldState: { invalid, isDirty, isTouched, error },
          formState: { disabled: formDisabled },
        }) => {
          const inputProps: InputComponentProps['input'] = {
            name: fieldName,
            onBlur,
            onChange,
            value,
          };

          const metaProps: InputComponentProps['meta'] = {
            invalid,
            dirty: isDirty,
            touched: isTouched,
            error: error?.message,
          };

          return (
            <FieldRenderer
              {...rest} // Для совместимости
              data={{ ...rest, InputRenderer: WrappedInput, options: rest }}
              input={inputProps}
              meta={metaProps}
              disabled={formDisabled || fieldDisabled || disabled}
              className={className}
              prepend={prepend}
              sx={sx}
              {...extraProps}
            >
              {children}
            </FieldRenderer>
          );
        }}
      />
    );
  };

export const ReactHookFormBuilder = {
  ...(mapValues(fieldTypes, ReactHookFormInputWrapper) as unknown as Record<
    keyof typeof fieldTypes,
    FC<ReactHookFormInputProps>
  >),
  Buttons: FormBuilder.Buttons,
  ButtonGroups: FormBuilder.ButtonsGroup,
};
