import { MouseEvent, useCallback, useRef, useState } from 'react';
import { xor } from 'lodash';
import { ClickAwayListener, Fade, Paper, Popper } from '@mui/material';
import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';

// eslint-disable-next-line import/no-cycle
import { Date } from 'lib/ui';
import { GlyphName } from 'lib/ui/icon';

// eslint-disable-next-line import/no-cycle
import { SelectInput } from '../select-input';
import './CalendarPicker.scss';

const { block, element } = bem('CalendarPicker');

interface CalendarPickerProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  label?: string;
  multiselect?: boolean;
  startIcon?: GlyphName;
  required?: boolean;
  error?: boolean;
  format?: string;
  fromNow?: boolean;
  hasArrow?: boolean;
  width?: string;
  placeholder?: string;
  minDate?: Moment;
  maxDate?: Moment;
}

const SelectedDay = (props: PickersDayProps<Moment> & { highlightedDays?: string[] }) => {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected = highlightedDays.includes(moment(day).toString());

  return (
    <PickersDay
      {...other}
      {...element('day', { selected: isSelected })}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
    />
  );
};

export const CalendarPicker = ({
  value,
  onChange,
  label,
  multiselect,
  startIcon,
  required,
  error,
  format = 'yyyy-MM-DD',
  fromNow,
  hasArrow,
  width,
  placeholder,
  minDate,
  maxDate,
}: CalendarPickerProps) => {
  const anchorEl = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState<string[]>(value ?? []);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (!isOpen && event.target) {
        anchorEl.current = event.target as HTMLDivElement;
        setIsOpen(true);
      }
    },
    [isOpen, anchorEl],
  );

  const handleClose = useCallback(() => {
    onChange?.(highlightedDays);
    setIsOpen((prev) => !prev);
  }, [highlightedDays, onChange]);

  const handleRemove = (removedDay: string) => {
    setHighlightedDays((prev) => prev.filter((i) => i !== removedDay));
  };

  const handlePickDay = (day: Moment | null) => {
    if (day == null) {
      setHighlightedDays([]);
      return;
    }

    const stringValue = moment(day).format(format);
    if (multiselect) {
      setHighlightedDays((items) => xor(items, [stringValue]));
      return;
    }

    setHighlightedDays([stringValue]);
    onChange?.([stringValue]);
    setIsOpen(false);
  };

  const handleClearValue = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation();
      onChange?.([]);
      setHighlightedDays([]);
    },
    [onChange],
  );

  const renderData = (data: string) =>
    data ? <Date value={moment(data, format)} showTime={false} /> : '';

  return (
    <div {...block()}>
      <SelectInput
        isOpen={isOpen}
        label={label}
        value={highlightedDays}
        onOpen={handleClick}
        multiple={multiselect}
        renderValue={renderData}
        startIcon={startIcon}
        onRemoveItem={handleRemove}
        required={required}
        error={error}
        ref={anchorEl}
        hasArrow={hasArrow}
        width={width}
        onClearValue={handleClearValue}
        placeholder={placeholder}
      />
      <Popper
        sx={{
          zIndex: 1400,
        }}
        open={isOpen}
        anchorEl={anchorEl.current}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper {...element('timeSelect')}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <DateCalendar
                    disablePast={fromNow}
                    onChange={handlePickDay}
                    minDate={minDate}
                    maxDate={maxDate}
                    slots={{
                      day: SelectedDay,
                    }}
                    slotProps={{
                      day: {
                        highlightedDays,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      } as any,
                    }}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};
