import { useState, useCallback, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import { bem } from 'lib/bem';
import { Icon } from 'lib/ui/icon';
import { brandedThemeOptions } from 'lib/ui/AppThemeProvider/theme';
import { UserModel } from 'app/users/types';
import { getLocalStorageItem, saveLocalStorageItem } from 'lib/helpers/localStorage';
import { Badge } from 'lib/ui/Badge';
import { useQueryParams } from 'lib/helpers/useQueryParams';
import { qsParse } from 'lib/helpers/qs';
import { IssueListCountersApiSchema } from 'shared/api/v4/swagger/data-contracts';

import SubMenu from '../SubMenu';
import './MenuItem.scss';

const { block, element } = bem('MenuItem');

interface Props {
  className?: string;
  item: Record<string, any>;
  pathname?: string;
  index?: number;
  showIcon?: boolean;
  hidden?: boolean;
  smallScreen?: boolean;
  onClick?: (evt) => void;
  user?: UserModel;
  counters?: IssueListCountersApiSchema;
}

const storageKey = 'menu-state';
const generateKey = (user: UserModel) => `user-${user.id}`;

const saveMenuItemState = (user, value) => {
  const storage = generateKey(user);
  const found = getLocalStorageItem(storage);
  if (found) {
    found[storageKey] = found[storageKey] ? [...found[storageKey], value] : [value];
    saveLocalStorageItem(storage, found);
  } else {
    saveLocalStorageItem(storage, { [storageKey]: [].push() });
  }
};

const removeMenuItemState = (user, value) => {
  const storage = generateKey(user);
  const found = getLocalStorageItem(storage);

  found[storageKey] = found[storageKey].filter((item) => item !== value);
  saveLocalStorageItem(storage, found);
};

const getMenuState = (user) => {
  const storage = generateKey(user);
  const found = getLocalStorageItem(storage);

  if (!found) {
    saveLocalStorageItem(storage, { [storageKey]: [] });

    return [];
  }

  if (!found[storageKey]) {
    saveLocalStorageItem(storage, { ...found, [storageKey]: [] });

    return [];
  }

  return found[storageKey];
};

export const MenuItem = ({
  className,
  item,
  pathname,
  index,
  hidden,
  smallScreen,
  onClick,
  showIcon = true,
  user,
  counters,
}: Props) => {
  const handleClick = useCallback(
    (evt) => {
      if (smallScreen) {
        onClick?.(evt);
      }
    },
    [onClick, smallScreen],
  );

  const newItem =
    item.subMenu?.length === 1
      ? {
          id: item.id,
          icon: item.icon,
          isItemActive: item.isItemActive,
          label: item.subMenu[0].label,
          route: item.subMenu[0].route,
        }
      : item;

  return (
    <Box onClick={handleClick} {...block({ showIcon }, className)} data-group={!!newItem.subMenu}>
      {newItem.subMenu
        ? GroupItem(newItem, hidden, index, handleClick, smallScreen, pathname, user, counters)
        : MenuLink(newItem, showIcon, pathname, hidden)}
    </Box>
  );
};

function isItemActive(item, pathname) {
  if (item.subMenu && isSubmenuActive(pathname, item.path)) {
    return true;
  }

  if (item.isItemActive) {
    return item.isItemActive(pathname);
  }

  return false;
}

function isSubmenuActive(routePathname, path) {
  return routePathname.match(new RegExp(`^${path}/`));
}

const useLinkStyles = makeStyles((theme: Theme) => ({
  active: {
    color: theme.palette.active,
    '& svg': {
      fill: theme.palette.active,
    },
  },
}));

// #TODO: в отдельный компонент
const MenuLink = (item, showIcon, _, hidden) => {
  const classes = useLinkStyles();
  const { route } = item;

  const { query } = useQueryParams();

  // Если у route есть queryParams, то проверяем что они совпадают с параметрами текущиго URL
  const queryParamsMatch = useMemo(() => {
    const itemQueryParams = qsParse((route.linkPath ?? route.path)?.split(/\?(.*)/s)?.at(1));

    if (isEmpty(itemQueryParams)) {
      return true;
    }

    return Object.entries(itemQueryParams).every(
      ([key, value]) => query[key] != null && query[key] === value,
    );
  }, [query, route]);

  const path = item.path ?? item?.route?.linkPath ?? item?.route?.path;
  const ContentComponent = item.renderContent;

  const { className } = element('link', { hidden });

  return (
    <NavLink
      className={({ isActive }) =>
        isActive && queryParamsMatch ? `${className} ${classes.active}` : className
      }
      to={path}
      preventScrollReset
    >
      {showIcon && item.icon && <Icon glyph={item.icon} {...element('icon')} />}

      {!hidden && <div {...element('text', { withIcon: !!item.icon })}>{item.label}</div>}

      {ContentComponent && <ContentComponent />}
    </NavLink>
  );
};

// #TODO: в отдельный компонент
const GroupItem = (item, hidden, index, handleClick, smallScreen, pathname, user, counters) => {
  const classes = useLinkStyles();
  const active = isItemActive(item, pathname);

  const renderSubMenuItem = useCallback(
    (subMenuItem) => <MenuItem item={subMenuItem} showIcon={false} onClick={handleClick} />,
    [handleClick],
  );

  const menuState = getMenuState(user);
  const [expanded, setExpanded] = useState(menuState.includes(item.label) ? item.label : false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      saveMenuItemState(user, panel);
    } else {
      removeMenuItemState(user, panel);
    }
  };

  useEffect(() => {
    if (hidden && expanded) {
      setExpanded(false);
    }
    if (!hidden) {
      setExpanded(menuState.includes(item.label) ? item.label : false);
    }
  }, [hidden, expanded, menuState, item.label]);

  return (
    <div>
      <Accordion
        disableGutters
        expanded={expanded === item.label}
        onChange={handleChange(item.label)}
        sx={{
          color: brandedThemeOptions.menuTextColor || '#FFF',
          fill: brandedThemeOptions.menuTextColor || '#FFF',
          background: brandedThemeOptions.baseColor,
          boxShadow: 'none',
        }}
      >
        <AccordionSummary
          {...element('accord', {}, active ? classes.active : '')}
          expandIcon={
            !hidden ? (
              <ExpandMoreIcon sx={{ color: brandedThemeOptions.menuTextColor || '#FFF' }} />
            ) : null
          }
          sx={{
            minHeight: '44px',
            padding: '0.5rem 0.5rem 0.5rem 20px',
            paddingLeft: hidden ? '12px' : '20px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
            '&:hover': {
              background: ({ palette }) => palette.customBackground?.highlight,
              borderRadius: '8px',
            },
          }}
        >
          <Icon glyph={item.icon} {...element('icon')} />

          {!hidden && <div {...element('text', { withIcon: true })}>{item.label}</div>}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: '0',
            '& .MenuItem__link': {
              paddingLeft: '60px',
            },
          }}
        >
          {item.subMenu.map((i, _index) => {
            if (counters && Object.keys(counters).includes(i.id)) {
              return (
                <Badge key={i.id} content={counters[i.id]?.unseen ?? 0}>
                  <MenuItem key={i.id} item={i} index={_index} />
                </Badge>
              );
            }
            return <MenuItem key={i.id} item={i} index={_index} />;
          })}
        </AccordionDetails>
      </Accordion>

      {item.subMenu && hidden && (
        <SubMenu
          index={index}
          items={item.subMenu}
          renderItem={renderSubMenuItem}
          smallScreen={smallScreen}
          counters={counters}
        />
      )}
    </div>
  );
};
