import { memo } from 'react';
import { AppBar } from '@mui/material';

import { Logo } from 'lib/ui';

import './MiniAppHeader.scss';

const { block } = bem('MiniAppHeader');

const MiniAppHeader = () => {
  return (
    <AppBar {...block()} sx={{ padding: 1 }}>
      <Logo />
    </AppBar>
  );
};

export default memo(MiniAppHeader);
